import React,{useEffect,useState} from 'react';
import { Link,useNavigate } from "react-router-dom";
import * as constants from "../../../../Constants";
import { favouriteUnFavouriteMark } from '../../../../services/Favourites';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { datediff } from '../../../../lib/Utils';

//Component Description - Recommended Content card component for content completion screen
export default function RecommendedContentCard({content,contentData,handleCompletionModal,handleContentDetail,handleVideoModal,handleIsLibraryjourney, isUserSubscribe,subscriptionSource,setsubscriptionSource,page,isContentUnlockFreePassUser,setRecommendedContent}) {
    const [add_to_fav,added_to_fav]=useState('');
    const [favourite,markFavourite]=useState(0);

    let navigate = useNavigate();
    useEffect(() => {
        if(content.engagement.is_favourite===1){
            added_to_fav("added_to_fav");
            markFavourite(1);
        }else{
            added_to_fav("");
            markFavourite(0);
        }
        }, [content])
    const videoPopupToggle = (e) => {
        e.preventDefault();
        if(isUserSubscribe === false && (content.metadata.custom_one.toLowerCase()!=="free" ) && isContentUnlockFreePassUser===false){
            setRecommendedContent([]);
            handleCompletionModal(false);
            setsubscriptionSource(page);
            navigate(`/dashboard/manage_subscription`);
            return true;
        }
        content.page=page;
        content.engagement.is_favourite=favourite;
        content.favouriteContentType="meditations";
        setRecommendedContent([]);
        handleCompletionModal(false);
        handleVideoModal(true);
        contentData(content);
        setTimeout(function(){
            window.$(".video-desc-text").mCustomScrollbar({
                setHeight : 235
            });
        },200);
      };
      let hours = "";
      let minutes = "";
      let seconds = "";
      let totalDuration = "";
      let date= new Date();
      hours = Math.floor(content.duration / 3600);
      minutes = Math.floor((content.duration - hours * 3600) / 60);
      seconds = Math.floor(content.duration - (hours * 3600 + minutes * 60));
      if(hours < 10){ hours= "0" + hours }
      if(minutes < 10){ minutes= "0" + minutes }
      if(seconds < 10){ seconds= "0" + seconds }
      totalDuration = hours > 0 ? hours +":"+minutes +":"+seconds : minutes +":"+seconds;

    const favouriteToggle = (e) => {
        e.preventDefault();

        if(isUserSubscribe === false){
            console.log(page);
            setRecommendedContent([]);
            handleCompletionModal(false);
            setsubscriptionSource(page);
            navigate(`/dashboard/manage_subscription`);
            return true;
        }

        var favouriteContentType = "meditations";
        let data = {
            userCode : localStorage.getItem('userCode'),
            superStoreId : content.super_store_id,
            storeId  : content.store_id,
            pageId   : content.page_id,
            portletId : content.portlet_id,
            contentId: content.id,
            markFavourite : (favourite===0) ? 1:0,
            favouriteContentType : favouriteContentType,
            locale : constants.LOCALE
        }

        if(data.markFavourite === 0){
            confirmAlert({
                // title: 'Confirm to submit',
                message: 'Do you want remove this from favourite list?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => FavouriteUnfavourite(data)
                    },
                    {
                        label: 'No',
                    }
                ],
                overlayClassName:"zindex9999"
            });
        }else{
            FavouriteUnfavourite(data);
        }
    };

    function FavouriteUnfavourite(payload){
        favouriteUnFavouriteMark(payload).then(response => {
            console.log(response)
            if(response.code === 200){
                if (add_to_fav!=="") {
                    added_to_fav("");
                    handleIsLibraryjourney(true);
                } else {
                    added_to_fav("added_to_fav");
                    handleIsLibraryjourney(true);
                }

                if(favourite!==0){
                    window.$("#favbox").fadeOut();
                    window.$("#unfavbox").fadeIn();
                    markFavourite(0);

                    setTimeout(function(){
                        window.$("#unfavbox").fadeOut();
                    },5000);
                }else{
                    window.$("#unfavbox").fadeOut();
                    window.$("#favbox").fadeIn();
                    markFavourite(1);

                    setTimeout(function(){
                        window.$("#favbox").fadeOut();
                    },5000);
                }
            }else{
                window.$("#unfavbox").fadeOut();
                window.$("#favbox").fadeOut();
                window.$("#error-message").fadeIn();
                window.$("#errortitle").html('');
                window.$("#errordesc").html(response.message);
                setTimeout(function(){
                    window.$("#error-message").fadeOut();
                },5000);
            }
        });
    }
    

  return (
    <div className="w-20 position-relative mb-3 recommeded-content">
        <div className="card mb-3 ">
            <Link to="" className="play-btn" onClick={(e)=>videoPopupToggle(e)}>
                <img src={content.entities.audio.thumb_image[0]}  className="card-img-top" alt="img"/>
                <div className="card-body p-2">
                <h5 className="card-title  two-line-ellipsis">{content.content_title} </h5>
                <p className="card-text">{content.metadata.singer && content.metadata.singer[0] ? content.metadata.singer[0] : ""}</p>
                <div className="d-flex justify-content-between mt-3 card-timer-play-icon-section">
                    <div className="timer">{totalDuration}</div>
                    {
                        content.metadata.custom_one.toLowerCase()==="free" || isUserSubscribe === true  || isContentUnlockFreePassUser===true  ? <i className="fas fa-play"></i> : <i className="fas fa-lock grayscale"></i>
                    }
                </div>
                </div> 
                {content.metadata.custom_two==="Hindi" ? <label className="latest-card">Hindi</label> : 
                datediff(content.publish_time,date) <= 15 ? <label className="latest-card">New</label> : ""}
            </Link>
            
            <Link to="" onClick={(e)=>favouriteToggle(e)} className="fav-btn">
                <i id="favorite_icon" className={`fas fa-heart ${add_to_fav}`}></i>
            </Link>
            
        </div> 
    </div>
  )
}
