import React,{useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate,Link } from 'react-router-dom';
import * as constants from "../../../../Constants";
import { getKeyValueStrings } from '../../../../services/Library';
import { favouriteUnFavouriteMark } from '../../../../services/Favourites';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

//Component Description - Content detail modal component for content description, go to warmup screen or direct play content, favourite and unfavourite content
export default function ContentDetailModal({content,contentData,detailModal,handleContentDetail,timerModal,handleTimerModal,videoModal,handleVideoModal,handleWarmUpTime,isJourney,setsubscriptionSource,isContentUnlockFreePassUser,handleIsLibraryjourney,isUserSubscribe}) {
  const [genericDesc,setGenericDesc]=useState('');
  const [add_to_fav,added_to_fav]=useState('');
  const [favourite,markFavourite]=useState(0);

  useEffect(() => {
    if(content.engagement.is_favourite===1){
        added_to_fav("added_to_fav");
        markFavourite(1);
    }else{
        added_to_fav("");
        markFavourite(0);
    }
      
    let keyPayload = {
      superStoreId    : constants.SUPER_STORE_ID,
      locale          : constants.LOCALE
    }
    getKeyValueStrings(keyPayload).then(result=>{
      setGenericDesc(result.data.Meditation_Generic_Desc);
    })
  }, [content])

  let navigate = useNavigate();
  let poster_url = "";
  if(content.content_type==="news" && content.entities.news_articles[0].media_url!==null){
      poster_url = content.entities.news_articles[0].media_thumb_url;
  }else if(content.content_type==="audio"){
      poster_url = content.entities.audio.thumb_image[0]
    }else if(content.content_type==="video"){
      poster_url = content.entities.video.thumb_image[0]
    }else{
      poster_url = content.entities.image.thumb_image[0];
  }
  let hours = "";
  let minutes = "";
  let seconds = "";
  let totalDuration = "";
  let contentDuration = content.duration ? content.duration : content.entities.news_articles[0].duration;
  hours = Math.floor(contentDuration / 3600);
  minutes = Math.floor((contentDuration - hours * 3600) / 60);
  seconds = Math.floor(contentDuration - (hours * 3600 + minutes * 60));
  if(hours < 10){ hours= "0" + hours }
  if(minutes < 10){ minutes= "0" + minutes }
  if(seconds < 10){ seconds= "0" + seconds }
  totalDuration = hours > 0 ? hours +":"+minutes +":"+seconds : minutes +":"+seconds;

  const warmupToggle = (e) => {
    e.preventDefault();
    if(isUserSubscribe === false && (content.metadata.custom_one.toLowerCase()!=="free" ) && isContentUnlockFreePassUser===false){
      handleContentDetail(false)
      setsubscriptionSource(content.page);
      navigate(`/dashboard/manage_subscription`);
      return true;
    }
    let time = document.getElementById("warmup_time").value;
    if(time > 0){
      handleWarmUpTime(time);
      handleTimerModal(true);
      handleContentDetail(false)
    }else{
      handleVideoModal(true);
      handleContentDetail(false)
    }
    
  };

  const favouriteToggle = (e) => {
    e.preventDefault();
    if(isUserSubscribe === false){
      handleContentDetail(false)
      setsubscriptionSource(content.page);
      navigate(`/dashboard/manage_subscription`);
      return true;
    }
    var favouriteContentType = content.favouriteContentType ;
    let data = {
        userCode : localStorage.getItem('userCode'),
        superStoreId : content.super_store_id,
        storeId  : content.store_id,
        pageId   : content.page_id,
        portletId : content.portlet_id,
        contentId: content.id,
        markFavourite : (favourite===0) ? 1:0,
        favouriteContentType : favouriteContentType,
        locale : constants.LOCALE
    }

    if(data.markFavourite === 0){
        confirmAlert({
            // title: 'Confirm to submit',
            message: 'Do you want remove this from favourite list?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => FavouriteUnfavourite(data)
                },
                {
                    label: 'No',
                }
            ],
            overlayClassName:"zindex9999"
        });
    }else{
        FavouriteUnfavourite(data);
    }
  };
  
  function FavouriteUnfavourite(payload){
      favouriteUnFavouriteMark(payload).then(response => {
          if(response.code === 200){
              if (add_to_fav!=="") {
                  added_to_fav("");
                  handleIsLibraryjourney(true);
                  content.engagement.is_favourite = 0;
              } else {
                  added_to_fav("added_to_fav");
                  handleIsLibraryjourney(true);
                  content.engagement.is_favourite = 1;
              }
  
              if(favourite!==0){
                  window.$("#favbox").fadeOut();
                  window.$("#unfavbox").fadeIn();
                  markFavourite(0);
  
                  setTimeout(function(){
                      window.$("#unfavbox").fadeOut();
                  },5000);
              }else{
                  window.$("#unfavbox").fadeOut();
                  window.$("#favbox").fadeIn();
                  markFavourite(1);
  
                  setTimeout(function(){
                      window.$("#favbox").fadeOut();
                  },5000);
              }
              //updating content data in state
              contentData(content);
          }else{
                window.$("#unfavbox").fadeOut();
                window.$("#favbox").fadeOut();
                window.$("#error-message").fadeIn();
                window.$("#errortitle").html('');
                window.$("#errordesc").html(response.message);
                setTimeout(function(){
                    window.$("#error-message").fadeOut();
                },5000);
          }
      });
  }
  let widthClass;
  let heightWidth;
  if(content.content_type==="video"){
    widthClass = "maxWidth480";
    heightWidth="heightWidth480"
  }else{
    widthClass = "maxWidth640";
    heightWidth="";
  }

    
  return (
    <Modal
    show={detailModal}
    onHide={() => handleContentDetail(false)}
    id="video-popup"
    dialogClassName={`modal-lg modal-dialog-centered ${widthClass}`}  
    tabIndex="-1"
    aria-labelledby="video-popups"
    aria-hidden="true"
    >

          <button type="button" className="btn-close video-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleContentDetail(false)}></button>
          {isJourney===false?
            <Link to="" onClick={(e)=>favouriteToggle(e)} className="fav-btn">
              <i className={`fas fa-heart ${add_to_fav}`}></i>
            </Link> : '' }
          <div className={`modal-body ${heightWidth}`} style={{height:"480px"}}>
            <video width="100%" height="100%" poster={poster_url}>
              <source src="" type="video/mp4"/>
            </video>
            <div className="video-desc">
              <div className="row">
                  <div className="video-title">
                    <ul className="list-unstyled mb-5 mt-2">
                      <li className="list-inline-item" >
                        {content.content_title} 
                        {
                            content.metadata.singer && content.metadata.singer[0] ? 
                            <i className='fa fa-solid fa-circle' style={{fontSize:'8px',verticalAlign: 'middle',color:"#fff",margin:"0 5px"}} ></i> : ''
                        }
                        {content.metadata.singer && content.metadata.singer[0] ? content.metadata.singer[0] : ''}
                      </li>
                    </ul>
                  </div>
              </div>
              <div className="row">
                <div className="col-7">
                  <div className="video-desc-text mb-5">
                    <p dangerouslySetInnerHTML={{__html: content.metadata.custom_four ? content.metadata.custom_four : genericDesc}}/>
                    
                  </div>
                  <div className="duration-video">
                    <div className="d-flex justify-content-between">
                      <div className="whitetxt">Duration </div>
                      <div className="whitetxt">{totalDuration?totalDuration:""}</div>
                    </div>
                    <hr/>
                    {
                      content.noWarmup  === true
                      ?
                      <input type="hidden" id='warmup_time' value={0}/>
                      : 
                      <div className="d-flex justify-content-between">
                        <div className="whitetxt">Warm Up Time  </div>
                        <div className="duration-select">
                          <select id='warmup_time'>
                            <option value={0}>0: 00</option>
                            <option value={10}>0: 10</option>
                            <option value={20}>0: 20</option>
                          </select>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="col-5 align-self-end">
                  <div className="video-btn-wrap ">
                    <Link to='' onClick={(e)=>warmupToggle(e)} className="player-btn round-btn-play btn-play-2">
                      {
                        (content.metadata.custom_one && content.metadata.custom_one.toLowerCase()==="free") || isUserSubscribe === true || isContentUnlockFreePassUser===true ? <i className="fas fa-play"></i> : <i className="fas fa-lock grayscale"></i>
                      }
                    </Link> 
                  </div>
                </div>
              </div>
            </div>
          </div>
  </Modal>
  )
}
