import React, {useState,useEffect} from 'react';
import axios from 'axios';
import * as constants from "../../../../Constants"
import { getUserProfileDetails, getStatesList } from '../../../../services/Profile';

import ErrorMessage from '../toast-message/ErrorMessage';
import { useOutletContext } from 'react-router-dom';

function ProfileUpload() {
    const outletData = useOutletContext();
    const [file, setFile] = useState();
    const [userName, setUserName] = useState();
    const [mobile, setMobile] = useState();
    const [email, setEmail]     = useState();
    const [gender, setGender]   = useState();
    const [state, setState]     = useState();
    const [profilePic, setProfilePic]     = useState("../dashboard-assets/images/avatar-2.png");

    const [stateList, setStateList]     = useState();
    // const [successMessage, setSuccessMessage]     = useState("");
    const [errorMessage, setErrorMessage]     = useState("");

    const [toastErrorMsgCategory, setToastErrorMsgCategory]     = useState("");
    const [toastErrorMsgTitle, setToastErrorMsgTitle]           = useState("");
    const [toastErrorMsgDesc, setToastErrorMsgDesc]             = useState("");

    let genders = ['Male','Female','Other'];
    let updateProfilePayload = {};

    function handleChange(event) {
        
        // console.log(event);

        if (event.target.name === 'name') {
            setUserName(event.target.value);
            updateProfilePayload.full_name = event.target.value
        }else if (event.target.name === 'email'){
            setEmail(event.target.value);
            updateProfilePayload.email = event.target.value
        }else if (event.target.name === 'gender'){
            setGender(event.target.value);
            updateProfilePayload.gender = event.target.value
        }else if (event.target.name === 'state'){
            setState(event.target.value);
            updateProfilePayload.state = event.target.value
        }else if (event.target.name === 'file'){
            // console.log(event.target.files[0].size)
            if(event.target.files[0].size<=5000000){
                setFile(event.target.files[0]);            
                updateProfilePayload.file = event.target.files[0]

                if (event.target.files && event.target.files[0]) {
                    setProfilePic(URL.createObjectURL(event.target.files[0]));
                }
            }else{
                setErrorMessage("Image size should be less than 5 MB");
                setToastErrorMsgCategory("profile");
                setToastErrorMsgTitle("");
                setToastErrorMsgDesc("Image size should be less than 5 MB");
            }
            
        }
    }
  
    function handleSubmit(event) {

        // console.log(file);
        // console.log(userName);
        

        event.preventDefault()
        const url = constants.API_URL+'v3/profile/updateProfile_1_1';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('full_name', userName);
        formData.append('email', email);
        formData.append('gender', gender);
        formData.append('stateId', state);


        // console.log("formData",formData);

        let payload = {};
        payload.media = file;
        payload.full_name   = userName;
        payload.email       = email;
        payload.gender      = gender;
        payload.stateId     = state;
        payload.superStoreId   = constants.SUPER_STORE_ID;
        payload.userCode       = localStorage.getItem('userCode');
        payload.status       = "modify";

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        axios.post(url, payload, config).then((response) => {            
            if(response.data.code === 200) {
                window.$("#profile-update-success").fadeIn();
                if(outletData.isProfileUpdated===false){
                    outletData.setIsProfileUpdated(true);
                }
                
                // setSuccessMessage(response.data.message);
                setErrorMessage("");
                setTimeout(function(){
                    window.$("#profile-update-success").fadeOut();
                },5000);

            }else{
                window.$("#error-message").fadeIn();
                setErrorMessage(response.data.message);
                // setSuccessMessage("");

                setToastErrorMsgCategory("profile");
                setToastErrorMsgTitle("");
                setToastErrorMsgDesc(response.data.message);
                
                setTimeout(function(){
                    window.$("#error-message").fadeOut();
                },5000);
            }
        });

    }

    let getUserPayload = {
        userCode: localStorage.getItem('userCode'),
        superStoreId: constants.SUPER_STORE_ID,
        locale      : constants.LOCALE
    }

    useEffect(() => {
        if(localStorage.getItem('userCode')){
            getUserProfileDetails(getUserPayload)
            .then( response => {
                setUserName(response.data.name);
                setMobile(response.data.mobile);
                setEmail(response.data.email);
                setGender(response.data.gender);
                setState(response.data.state_id);
    
                if( response.data.profile_pic_url !== "" && response.data.profile_pic_url !== null ){
                    setProfilePic(response.data.profile_pic_url);
                }
                
            });
        
            let getStatePayload = getUserPayload;
        
            getStatesList(getStatePayload)
            .then( response => {
                setStateList(response.data);
            });
        }
    }, [outletData.isProfileUpdated])
    
    return (
        <div className="profile_form">
           {
                errorMessage && errorMessage !=="" ? (<ErrorMessage msgCategory={toastErrorMsgCategory} msgTitle={toastErrorMsgTitle} msgDesc={toastErrorMsgDesc} />) : ""
           }
            
            
        {/* <div className="toast toast-message-popup" data-autohide="false">
           
            {
                successMessage && successMessage !=""  ?
                <div className="toast-body btn-success">
                    <div className="success-message">{successMessage}</div>
                    <button type="button" className="ml-2 mb-1 close justify-content-right" data-dismiss="toast">&times;</button>
                </div>
                : ""
            }
            
            {
                errorMessage && errorMessage !="" ?
                <div className="toast-body btn-danger">
                    <div className="success-message">{errorMessage}</div>
                    <button type="button" className="ml-2 mb-1 close justify-content-right" data-dismiss="toast">&times;</button>
                </div>
                : ""
            }

        </div> */}
        <form onSubmit={handleSubmit}>
            <div className="profile-picture position-relative edit-prof-pic mb-4">
                <div className="prev-image-bdr d-flex align-items-center justify-content-center">
                        <img src={profilePic} alt="profile-img" className="img-fluid preview1" id="ImgPreview"/>
                        
                </div>
                <div className="edit-profile">
                    <label htmlFor="profile-upload-input">
                        <span className="d-flex justify-content-center align-items-center" id="Cs_edit"><i className="fas fa-pen"></i></span>
                        <input id="profile-upload-input" className="profile-upload-input" type="file" name="file" onChange={handleChange}/>
                    </label>
                    
                </div>
                
            </div>
            
            <div className="row">
                <div className="col-10">
                    <div className="row">
                        <div className="col-6">
                            <div className="mb-3">
                                <label>Name <span>*</span></label>
                                <input type="text" name="name" className="form-control" placeholder="Name" value={userName} onChange={handleChange}  required/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mb-3">
                                <label>Email <span>*</span></label>
                                <input type="email" name="email" className="form-control" placeholder="Email" value={email} onChange={handleChange} required/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mb-3">
                                <label>Mobile Number <span>*</span></label>
                                <input type="text" name="mobile" className="form-control" placeholder="Mobile Number" value={mobile} readOnly/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mb-3">
                                <label>Gender <span>*</span></label>
                                <select className="form-select" name="gender" value={gender} onChange={handleChange}>
                                    <option value="">Select gender</option>
                                    {
                                        genders.map(gender => {
                                            return (<option value={gender}>{gender}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mb-3">
                                <label>State</label>
                                <select className="form-select" name="state" value={state} onChange={handleChange}>
                                <option value="">Select state</option>
                                { stateList && stateList.length>0 ?
                                    (stateList.map((stateDetail,i) => 
                                        <option value={stateDetail.id}>{stateDetail.name}</option>
                                    )) : (<h1>No results found!</h1>)
                                }
                                </select>
                                
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 mb-3">
                        <button className="save-btn" type="submit">Save</button>
                    </div>
                </div>
            </div>
        </form>
        </div>
    );
}

export default ProfileUpload;