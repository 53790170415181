import React from 'react';
import Modal from 'react-bootstrap/Modal';

//Component Description - testimonial video player modal component for userbased landing pages
export default function TestimonialVideoModal({src,setSrc,iframeModal,setIframeModal}) {
    const closeModal = ()=>{
        setSrc('');
        setIframeModal(false);
    }
  return (
    <Modal
    show={iframeModal}
    onHide={() => closeModal()}
    id="videoPopup" 
    dialogClassName=" modal-dialog-centered modal-dialog-scrollable modal-lg"
     tabIndex="-1" 
     aria-labelledby="video-popups" 
     aria-hidden="true"
    >
        <div className="modal-content">
            <div className="modal-header">
                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => closeModal()} aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <iframe id="videoIFrame" width="100%" height="400" src={src} className="video-id"></iframe> 
            </div>
        </div>
    </Modal>
  )
}
