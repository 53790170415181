import * as constants from "../Constants";

export async function getDateWisePortletsContents(data){
    const response = await fetch(constants.API_URL+`v3/portlet/getDateWisePortletsContents_1_0` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}


export async function getPortletDataWithVideoStats(data){
    const response = await fetch(constants.API_URL+`v3/portlet/getWebAffirmationLibrary` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}

export async function getPortletDetails(data){
    const response = await fetch(constants.API_URL+`v3/portlet/getPortletDetails_1_0` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}