import React,{useEffect,useState} from 'react';
import { NavLink,Link, useNavigate} from "react-router-dom";
import { getUserProfileDetails } from '../../../../services/Profile';
import * as constants from "../../../../Constants"
import { getSearchKeywords } from '../../../../services/Search';
import { confirmAlert } from 'react-confirm-alert';

//Component Description - Header component for dashboard template
export default function Header({isProfileUpdated,setIsProfileUpdated,searchKeyword,setSearchKeyword}) {
	const [userName,setUserName]=useState('');
	const [profileImg,setProfileImg]=useState(''); 
	const [isClearBtn,setIsClearBtn]=useState(false); 
	let navigate = useNavigate();
	function searchResult(){
		setTimeout(() => {
			setIsClearBtn(true)
			setSearchKeyword(window.$("#search").val());
			navigate(`/dashboard/search`);
		}, 250);
	}
	var arr=[];
	function getValues(item, i) {
		arr[i] = item.search_term; 
	}
	useEffect(() => {
		let payload ={
			superStoreId: constants.SUPER_STORE_ID,
			locale      : constants.LOCALE
		}
		getSearchKeywords(payload).then(res=>{
			var availableTags = res.data.all;
			var trendingTags = res.data.trending_tags;
			trendingTags.forEach(getValues);
			//global search script important
			window.$( function() {
				window.$("#search").autocomplete({
					// source: strLen<=3 ? arr : availableTags,
					source: function( request, response ) {
						if(window.$('#search').val().length ===0 && searchKeyword===""){
							response(arr);
						}else{
							var matcher = new RegExp( "^" + window.$.ui.autocomplete.escapeRegex(window.$('#search').val() ), "i" );
							response( window.$.grep( availableTags, function( item ){
								return matcher.test( item );
							}) );
						}
					},
					open: function()
					{
						if(window.$('#search').val().length ===0 && searchKeyword===""){
							window.$('ul.ui-autocomplete').prepend('<li class="ui-menu-item ui-header-item"><div class="item-header"><b>Trending Tags<b/></div></li>');
						}else{
							window.$('ul.ui-autocomplete').prepend('<li class="ui-menu-item ui-header-item"><div class="item-header"><b>Showing results for "'+window.$('#search').val()+'"<b/></div></li>');
						}
						if(window.$('#search').val().length > 0){
							setIsClearBtn(true)
						}else{
							setIsClearBtn(false)
						}
					},
					minLength: 0,
					select: function(e, ui) { 
						searchResult()
					}
				}).on('focus', function(){ window.$("#search").autocomplete("instance").search(""); })
				.bind( "keydown", function( event ) {
					if ( event.keyCode === 13) {
						// check for 3 characters
						if(window.$('#search').val().length >= 3){
							event.preventDefault();
							searchResult()
							window.$(".ui-menu").hide();
						}else{
							event.preventDefault();
							window.$(".ui-menu").hide();
							confirmAlert({
								// title: 'Confirm to submit',
								message: 'The field cannot be left blank or have fewer than 3 characters!',
								buttons: [
									{
										label: 'Ok',
									}
								],
								overlayClassName:"zindex9999"
							});
							return false;
						}
					}
				});
			} );
		})
		
		if( localStorage.getItem('userCode') !== null ){
			let getUserPayload = {
				userCode: localStorage.getItem('userCode'),
				superStoreId: constants.SUPER_STORE_ID,
				locale      : constants.LOCALE
			}
	
			getUserProfileDetails(getUserPayload)
			.then( response => {
				setUserName(response.data.name);
				setProfileImg(response.data.profile_pic_url)
				if(isProfileUpdated===true){
					setIsProfileUpdated(false);
				}
			});	
		}
	}, [isProfileUpdated])
	let profilePic = "";

	if(profileImg && profileImg !=="" &&
	profileImg !== null
	){
		profilePic= <img src={profileImg} className="img-fluid preview1" alt='profilepic' id="ImgPreview"/>
	}else{
		profilePic= <img src="../dashboard-assets/images/avatar.png" alt='profilepic' className="img-fluid preview1" id="ImgPreview"/>
	}

	const logout = (e) => {
		// e.preventDefault();
		// localStorage.clear();
		localStorage.setItem('isLoggedIn', false);
		window.location.reload();
	}


	const clearInput = (e) => {
		e.preventDefault();
		window.$("#search").val('');
		window.$("#search").focus();
	}
	
  return (
	<div className="container-fluid" style={{marginTop: `83px`}}>
			<header className="fixed-top dashboard-header">
				<div className="d-flex justify-content-between">
					<div className="nav-brand align-self-center me-auto">
						<Link to="">
							<img src="../dashboard-assets/images/logo.png" alt='' className="img-fluid"/>
						</Link>
					</div>
					<div className="search-form align-self-center">
						<form className="me-3" >
							<div className="position-relative">
								<input type="search" name="search" id="search" className="form-control" placeholder="E.g. Affirmation" aria-label="Search"/>
								{
									isClearBtn ?
									<button className="search-btn" onClick={(e)=>clearInput(e)}>x</button>
									 :
									<button className="search-btn"><i className="fas fa-search"></i></button>
								}
								
							</div>
						</form>
					</div>
					<div className="right-side-head d-flex">
						<div className="profile-details">
							<div className="navbar-nav">
								<li></li>
								<li className="nav-item dropdown">
								<Link className="nav-link dropdown-toggle" to="" id="profile_drop" role="button" data-bs-toggle="dropdown" aria-expanded="false">Hi, {userName+"! "}  
									{profilePic}
								</Link>
								<ul className="dropdown-menu" aria-labelledby="profile_drop">
									<li><NavLink to="/dashboard/manage_subscription" className="dropdown-item">Manage Subscription</NavLink></li>
									<li><NavLink to="/dashboard/profile_details" className="dropdown-item">Profile Details </NavLink></li>
									<li><Link to="/"  className="dropdown-item" onClick={ (e) => logout(e)} >Log Out</Link></li>
								</ul>
								</li>
							</div>
	
						</div>
					</div>
				</div>
			</header>
		</div>
  )
}
