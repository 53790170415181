import React from 'react';
import { Link } from 'react-router-dom';

//Component Description - Footer component for dashboard template
export default function Footer() {

  function closeToastMsg(){
    window.$(".favbox").fadeOut();
  }

  return (
    <>
        <footer>
          <hr/>
          <div className="d-flex justify-content-between">
            <a href="https://www.thinkrightme.com" target="_blank"  rel="noopener noreferrer"><span><i className="fa fa-arrow-left" aria-hidden="true"></i> Thinkright.me</span></a>
            <span>&copy; {new Date().getFullYear()} JETSYNTHESYS PVT. LTD. ALL RIGHTS RESERVED.</span>
          </div>
        </footer>
        
        {/* {START Favourite message html} */}
        <div id="favbox" className='favbox' style={{display: 'none'}}>
            <Link to="#" className="closefavbox" onClick={closeToastMsg} >
                <svg viewport="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <line x1="1" y1="11"  x2="11" y2="1"  stroke="black"  strokeWidth="2"/>
                    <line x1="1" y1="1"  x2="11" y2="11"  stroke="black"  strokeWidth="2"/>
                </svg>
            </Link>
            <div className="favourite">
                <div className="no-fav-content min-height-auto">
                <span className="mb-2"><i className="fas fa-heart"></i></span> Favourite Added
                <p className="mb-0">Content added in your favourite list</p>
                </div>
            </div>
        </div>
        {/* {END Favourite message html} */}

        {/* {START Unfavourite message html} */}
        <div id="unfavbox" className='favbox' style={{display: 'none'}}>
            <Link to="#" className="closefavbox" onClick={closeToastMsg} >
                <svg viewport="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <line x1="1" y1="11"  x2="11" y2="1"  stroke="black"  strokeWidth="2"/>
                    <line x1="1" y1="1"  x2="11" y2="11"  stroke="black"  strokeWidth="2"/>
                </svg>
            </Link>
            <div className="favourite">
                <div className="no-fav-content min-height-auto">
                <span className="mb-2"><i className="fas"></i></span> Favourite Removed
                <p className="mb-0">Content Removed from your favourite list</p>
                </div>
            </div>
        </div>
        {/* {END Unfavourite message html} */}

        {/* {All Success message message html} */}
        <div id="profile-update-success" className='favbox' style={{display: 'none'}}>
            <Link to="#" className="closefavbox" onClick={closeToastMsg} >
                <svg viewport="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <line x1="1" y1="11"  x2="11" y2="1"  stroke="black"  strokeWidth="2"/>
                    <line x1="1" y1="1"  x2="11" y2="11"  stroke="black"  strokeWidth="2"/>
                </svg>
            </Link>
            <div className="favourite">
                <div className="no-fav-content min-height-auto">
                <p className="mb-0">Profile Updated successfully</p>
                </div>
            </div>
        </div>
        {/* {END All success message message html} */}

        {/* {All error message message html} */}
        <div  id="error-message" className="favbox" style={{display: 'none'}}>
            <Link to="#" className="closefavbox" onClick={closeToastMsg} >
                <svg viewport="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <line x1="1" y1="11"  x2="11" y2="1"  stroke="black"  strokeWidth="2"/>
                    <line x1="1" y1="1"  x2="11" y2="11"  stroke="black"  strokeWidth="2"/>
                </svg>
            </Link>
            <div className="favourite">
                <div className="no-fav-content min-height-auto">
                    <span id='errortitle'></span>
                    <p className="mb-0" id='errordesc'></p>
                </div>
            </div>
        </div>
        {/* {END error success message message html} */}
    </>
  )
}
