import React from 'react';
import Header from './main/dashboard/include/Header';
import Sidebar from './main/dashboard/include/Sidebar';
import Footer from './main/dashboard/include/Footer';
import '../dashboard-assets/css/style.css';
import ContentDetailModal from './main/dashboard/content-card/ContentDetailModal';
import {
  Outlet,Navigate 
} from "react-router-dom";
import PlayerTimer from './main/dashboard/content-card/PlayerTimer';
import VideoCard from './main/dashboard/content-card/VideoCard';
import {getStore} from '../services/Store';
import * as constants from "../Constants";
import ContentCompletionModal from './main/dashboard/content-card/ContentCompletionModal';
import ShareSocial from './main/dashboard/content-card/ShareSocial';
import { getAppConfig } from '../services/AppConfig';
import { getUserSubscriptionStatus } from '../services/InApp';
import MiniPlayer from './main/dashboard/content-card/MiniPlayer';

//Component Description - this component handle dashboard template, set store data, creating global states to be used in common components 
export default class Mainpage extends React.Component{
  Config        = [];
  config_subgroup_id = [];
  config_home_subgroup_id = [];
  subgroup_arr = [];
  store_arr = [];
  meditation_arr = [];
  sound_arr = [];
  shareDetails = {
    desc:"Hi! I felt great after this session on ThinkRight.me app and wanted to share that experience with you. You can download the app to explore its benefits.",
    link:"https://www.thinkright.me/getAppSubscription.php"
  }
  constructor(props) {

    // console.log("Mainpage js");
    super(props);
    this.state = {
      isUserSubscribe : false,
      isProfileUpdated : false,
      subscriptionSource : "",
      voucherPurchaseSource : "",
      openMiniPlayer : false,
      openDetailModal : false,
      openTimerModal : false,
      openVideoModal : false,
      openCompletionModal : false,
      openSocialShare : false,
      isJourney : false,
      journeyDate : "",
      isLibraryjourney : false,
      libraryJourneyDate : "",
      isFavouriteJourney :false,
      dailyAffirmComponent:new Date(),
      contentData : {},
      meditationData : [],
      soundData : [],
      yogaData : [],
      warmUpTime : "",
      triviaText:"",
      config            : this.props.configData,
      storeData         : [],
      store_arr         : [],
      store_id          :"",
      meditation_store_id : "",
      sound_store_id : "",
      yoga_store_id : "",
      searchSource : "",
      storePayload:{
        superStoreId    : constants.SUPER_STORE_ID,
	      userCode        : localStorage.getItem('userCode'),
        locale          : constants.LOCALE
      },

      redirecLogin: false,
    };
  }

  componentDidMount()  {
    let configPayload = {
      package_name : constants.PACKAGE_NAME,
      platformType : constants.LOCALE.platform,
      locale       : constants.LOCALE
    }

    getAppConfig(configPayload)
		.then((responseConfig) => {
      this.Config = responseConfig.data.menuSettings;
      getStore(this.state.storePayload)
      .then((response) => {
        this.setState({
          storeData: response.data.groups
        });
        this.config_subgroup_id = this.Config.bottom_sub_group_id;
        this.config_home_subgroup_id = this.Config.home_sub_group_id_v3;
        response.data.groups.forEach((groups)=>{
          groups.sub_groups.forEach((subgroups)=>{           
            if(parseInt(subgroups.sub_group_id)===parseInt(this.config_home_subgroup_id)){
              this.subgroup_arr=subgroups.stores;
              subgroups.stores.forEach((store)=>{
                this.setState({ store_id:  store.store_id });
                this.store_arr = store.store_pages.main;
              })
            }else{
              subgroups.stores.forEach((store)=>{
                if(store.store_title.includes("Meditation_ThinkRight_V3")){
                  this.meditation_arr = store.store_pages;
                  this.setState({ 
                    meditation_store_id:  store.store_id,
                    meditationData:store.store_pages
                  });
                }
                if(store.store_title.includes("Sound_ThinkRight_V3")){
                  this.sound_arr = store.store_pages;
                  this.setState({ 
                    sound_store_id:  store.store_id,
                    soundData:store.store_pages
                  });
                } 
                if(store.store_title.includes("Yoga_ThinkRight")){
                  this.setState({ 
                    yoga_store_id:  store.store_id,
                    yogaData:store.store_pages
                  });
                }     
              })
            }
          })
        })
      });
		})

    if(!localStorage.getItem('isLoggedIn') || localStorage.getItem('isLoggedIn') === "false" ){
      this.setState({ redirectLogin: true });
    }else{
      let getUserSubscriptionPayload = {
          superStoreId    : constants.SUPER_STORE_ID,
          userCode        : localStorage.getItem('userCode'),
          locale          : constants.LOCALE
      }

      getUserSubscriptionStatus(getUserSubscriptionPayload).then(response =>{
          if(response.code === 200){
              // console.log(response.data);
              this.setState({isUserSubscribe:true });
          };
      }) 
    } 
	}
  
  // state handler functions to change states in any of the respective component
  handleContentDetail = openDetailModal => {
    this.setState({ openDetailModal })
  }

  handleTimerModal = openTimerModal => {
    this.setState({ openTimerModal })
  }

  handleVideoModal = openVideoModal => {
    this.setState({ openVideoModal })
  }

  handleContentData = contentData => {
    this.setState({ contentData })
  }

  handleWarmUpTime = warmUpTime => {
    this.setState({ warmUpTime })
  }

  handleCompletionModal = openCompletionModal => {
    this.setState({ openCompletionModal })
  }

  handleIsJourney = isJourney =>{
    this.setState({isJourney})
  }

  handlejourneyDate = journeyDate =>{
    this.setState({journeyDate})
  }

  handleSocialShare = openSocialShare =>{
    this.setState({openSocialShare})
  }

  handleIsLibraryjourney = isLibraryjourney =>{
    this.setState({isLibraryjourney})
  }

  handleLibraryJourneyDate = libraryJourneyDate =>{
    this.setState({libraryJourneyDate})
  }

  handleIsFavouriteJourney = isFavouriteJourney =>{
    this.setState({isFavouriteJourney})
  }

  handleDailyAffirmComponent = dailyAffirmComponent =>{
    this.setState({dailyAffirmComponent})
  }

  setTriviaText = triviaText =>{
    this.setState({triviaText})
  }

  setIsProfileUpdated = isProfileUpdated =>{
    this.setState({isProfileUpdated})
  }

  setSearchSource = searchSource =>{
    this.setState({searchSource})
  }

  handleMiniPlayer = openMiniPlayer =>{
    this.setState({openMiniPlayer})
  }

  render() {
    const { redirectLogin } = this.state;

		if (redirectLogin) {
      return <Navigate to='/'/>;
		}
  //Dashboard template with header, sidebar, footer and content page
  //outlet provides context data to respective component included through Route (refer App.js)
  return (
    <>
    <Header isProfileUpdated={this.state.isProfileUpdated} setIsProfileUpdated={this.setIsProfileUpdated} 
    searchKeyword={this.props.searchKeyword} setSearchKeyword={this.props.setSearchKeyword}/>
      <div className="row mx-auto">
        <div className="col-lg-3 col-sm-1">
          <Sidebar/>
        </div>

        <Outlet context={{
          store_arr:this.store_arr,store_id:this.state.store_id,
          setIsProfileUpdated:this.setIsProfileUpdated,isProfileUpdated:this.state.isProfileUpdated ,
          meditation_store_id:this.state.meditation_store_id, meditationData:this.state.meditationData, 
          sound_store_id:this.state.sound_store_id, soundData:this.state.soundData, 
          yoga_store_id:this.state.yoga_store_id, yogaData:this.state.yogaData, 
          helpUrls:this.props.helpUrls,isContentUnlockFreePassUser:this.props.isContentUnlockFreePassUser,
          detailModal:this.handleContentDetail ,timerModal:this.handleTimerModal,videoModal:this.handleVideoModal , 
          contentData:this.handleContentData, isJourney:this.state.isJourney,handleIsJourney:this.handleIsJourney, 
          showOfferPopUp:this.props.showOfferPopUp, handleShowOfferPopUp:this.props.handleShowOfferPopUp, 
          journeyDate:this.state.journeyDate, handlejourneyDate:this.handlejourneyDate,
          subscriptionSource:this.props.subscriptionSource, setsubscriptionSource:this.props.setsubscriptionSource,
          voucherPurchaseSource:this.props.voucherPurchaseSource, setvoucherPurchaseSource:this.props.setvoucherPurchaseSource,
          isLibraryjourney:this.state.isLibraryjourney, handleIsLibraryjourney:this.handleIsLibraryjourney,
          libraryJourneyDate:this.state.libraryJourneyDate, handleLibraryJourneyDate:this.handleLibraryJourneyDate,
          isFavouriteJourney:this.state.isFavouriteJourney, handleIsFavouriteJourney:this.handleIsFavouriteJourney,
          dailyAffirmComponent:this.state.dailyAffirmComponent, handleDailyAffirmComponent:this.handleDailyAffirmComponent,
          searchSource:this.state.searchSource, setSearchSource:this.setSearchSource }} />
      </div>
    <Footer/>
      
    {
      //content description modal for any audio/video content
      this.state.openDetailModal && <ContentDetailModal 
      content={this.state.contentData} contentData={this.handleContentData}
      detailModal={this.state.openDetailModal} handleContentDetail={this.handleContentDetail} 
      timerModal={this.state.openTimerModal} handleTimerModal={this.handleTimerModal}
      videoModal={this.state.openVideoModal} handleVideoModal={this.handleVideoModal}
      handleWarmUpTime={this.handleWarmUpTime} 
      isJourney={this.state.isJourney} handleIsJourney={this.handleIsJourney} 
      setsubscriptionSource={this.props.setsubscriptionSource} 
      isContentUnlockFreePassUser={this.props.isContentUnlockFreePassUser}
      handleIsLibraryjourney={this.handleIsLibraryjourney} isUserSubscribe={this.state.isUserSubscribe}/>
     }

    {
      //Warm up timer screen modal component for meditation content
      this.state.openTimerModal && <PlayerTimer 
      content={this.state.contentData} 
      detailModal={this.state.openDetailModal} handleContentDetail={this.handleContentDetail} 
      timerModal={this.state.openTimerModal} handleTimerModal={this.handleTimerModal}
      videoModal={this.state.openVideoModal} handleVideoModal={this.handleVideoModal}
      warmUpTime={this.state.warmUpTime} handleWarmUpTime={this.handleWarmUpTime}
      handleIsJourney={this.handleIsJourney}  isJourney={this.state.isJourney}/>
    }

    {
      //Main player modal component to play any audio/video content
      this.state.openVideoModal && <VideoCard
      content={this.state.contentData} handleContentData={this.handleContentData}
      detailModal={this.state.openDetailModal} handleContentDetail={this.handleContentDetail} 
      timerModal={this.state.openTimerModal} handleTimerModal={this.handleTimerModal}
      videoModal={this.state.openVideoModal} handleVideoModal={this.handleVideoModal}
      handleWarmUpTime={this.handleWarmUpTime} handleCompletionModal={this.handleCompletionModal}
      handleIsJourney={this.handleIsJourney} isJourney={this.state.isJourney}
      openSocialShare={this.state.openSocialShare} handleSocialShare={this.handleSocialShare}
      setsubscriptionSource={this.props.setsubscriptionSource} 
      handleIsLibraryjourney={this.handleIsLibraryjourney} isUserSubscribe={this.state.isUserSubscribe} 
      openMiniPlayer={this.state.openMiniPlayer} handleMiniPlayer={this.handleMiniPlayer}/>
    }

    {
      //Post Content completion scrren modal component to show recommended content and sharing
      this.state.openCompletionModal && <ContentCompletionModal 
      content={this.state.contentData} contentData={this.handleContentData}
      detailModal={this.state.openDetailModal} handleContentDetail={this.handleContentDetail} 
      timerModal={this.state.openTimerModal} handleTimerModal={this.handleTimerModal}
      videoModal={this.state.openVideoModal} handleVideoModal={this.handleVideoModal}
      completionModal={this.state.openCompletionModal} handleCompletionModal={this.handleCompletionModal}
      openSocialShare={this.state.openSocialShare} handleSocialShare={this.handleSocialShare}
      subscriptionSource={this.props.subscriptionSource} setsubscriptionSource={this.props.setsubscriptionSource} 
      handleIsLibraryjourney={this.handleIsLibraryjourney} 
      isUserSubscribe={this.state.isUserSubscribe} isContentUnlockFreePassUser={this.props.isContentUnlockFreePassUser}/>
    }

    {
      //Share Modal component for Social media
      this.state.openSocialShare && <ShareSocial openSocialShare={this.state.openSocialShare} handleSocialShare={this.handleSocialShare} 
      shareDetails={this.shareDetails}/>  
    }
    
    { 
      //Miniplayer modal component for music/sound content only
      this.state.openMiniPlayer && <MiniPlayer content={this.state.contentData} handleContentData={this.handleContentData} openMiniPlayer={this.state.openMiniPlayer} handleMiniPlayer={this.handleMiniPlayer} videoModal={this.state.openVideoModal} handleVideoModal={this.handleVideoModal} handleIsLibraryjourney={this.handleIsLibraryjourney} isUserSubscribe={this.state.isUserSubscribe} setsubscriptionSource={this.props.setsubscriptionSource}/> 
    }
    </>
  )
  }
}
