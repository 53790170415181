import React,{useEffect,useState} from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Webpage from './components/Webpage';
import Mainpage from './components/Mainpage';
import Home from './components/main/dashboard/Home';
import DailyAffirmation from './components/main/dashboard/DailyAffirmation';
import Profile from './components/main/dashboard/profile/Profile';
import NotFound from './components/main/NotFound';
import * as constants from "./Constants";
import {getAppConfig,getWebAppLocaleVersion} from "./services/AppConfig";
import ScrollToTop from "./ScrollToTop";
import ComingSoon from './components/main/ComingSoon';
import Meditation from './components/main/dashboard/Meditation';
import { Oval } from 'react-loader-spinner'
import Sound from './components/main/dashboard/Sound';
import MorningZen from './components/main/dashboard/MorningZen';
import { getUserBase } from './services/User';
import Yoga from './components/main/dashboard/Yoga';
import GlobalSearchResult from './components/main/dashboard/GlobalSearchResult';

// START code at the time of version 4.7 changes to display stay tuned pages
import UnsubUserHome from './components/landing/userbased-landing/UnsubUserHome';
import Footer from './components/landing/include/Footer'
import logo from './assets/images/logo.svg'
// END code at the time of version 4.7 changes to display stay tuned pages


//Description - Routing of module components, fetch config API
function App() {
  const [configData, setconfigData] = useState([]);
  const [displayLoader,setdisplayLoader] = useState(true);
  const [helpUrls, sethelpUrls] = useState([]);
  const [isContentUnlockFreePassUser,setIsContentUnlockFreePassUser] = useState(false);
  const [showOfferPopUp,handleShowOfferPopUp] = useState(false);
  const [subscriptionSource, setsubscriptionSource ] = useState("");
  const [searchKeyword, setSearchKeyword ] = useState("");
  const [voucherPurchaseSource, setvoucherPurchaseSource ] = useState("");

  {/* START code at the time of version 4.7 changes to display stay tuned pages */}
  const [unsubHomeContent,setunsubHomeContent]=useState([]);
  {/* END code at the time of version 4.7 changes to display stay tuned pages */}

  useEffect(() => {
    let webAppLocaleVersionPayload ={};
    webAppLocaleVersionPayload.superStoreId = constants.SUPER_STORE_ID;
    webAppLocaleVersionPayload.keyword      = "WEB_APP_LOCALE_VERSION";

    getWebAppLocaleVersion(webAppLocaleVersionPayload).then(response =>{
      if(response.code === 200){
        localStorage.setItem("version",response.data.webAppLocaleVersion);
        constants.LOCALE.version = response.data.webAppLocaleVersion;
        
        let configPayload = {
          package_name : constants.PACKAGE_NAME,
          platformType : constants.LOCALE.platform,
          locale       : constants.LOCALE
        }
    
        //get app configuration-set config data, set help urls for e.g. default description and  getuserbase for freepass user content access
        getAppConfig(configPayload)
        .then((response) => {
          setconfigData(response.data.menuSettings);
          sethelpUrls(response.data.helpUrls);
          if(localStorage.getItem('userCode')){
            let payload = {
                superStoreId    : constants.SUPER_STORE_ID,
                userCode        : localStorage.getItem('userCode'),
                locale          : constants.LOCALE
            }
            getUserBase(payload).then(userResponse=>{
              if(userResponse.code === 200){
                localStorage.setItem('userBase',userResponse.data.userBase);
                setIsContentUnlockFreePassUser(response.data.isContentUnlockFreePassUser===1 && userResponse.data.userBase.toLowerCase()=== "fresh" ? true : false)
              }
            })
          }
          
          setTimeout(function(){
            setdisplayLoader(false);
          },500);
          })

      }
    });

    
	},[]);

  return (
    <>
    {/* START code at the time of version 4.7 changes to display stay tuned pages */}
      <header id="header" className="header">
      <nav className="navbar navbar-expand-lg navbar-light bg-light cs-menu">
        <div className="container-fluid">
          <a className="navbar-brand" to="">
            <img src={logo} alt="" className="img-fluid custom-logo"/>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarmenu" aria-controls="navbarmenu" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarmenu">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link " aria-current="page" href="https://www.thinkrightme.com">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link " href="https://www.thinkrightme.com/meditation/">Meditation </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " href="https://www.thinkrightme.com/articles/">Articles </a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" to="/">Web App </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://www.thinkrightme.com/corporate-wellness/">Corporate Plans  </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://www.thinkrightme.com/hi/articles-hi/">TRM हिन्दी  </a>
              </li>
              <li className="nav-item dropdown">
                
              </li>
            </ul>
            <div className="dropdown">
                <a className="nav-link dropdown-toggle button-style-rect" to="" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Download Now
                </a>
                <div className="dropdown-menu os-drop-btn" aria-labelledby="navbarDropdown" data-bs-popper="none">
                    <div className="p-3">
                      <p className="downl-app-text">Download  The App </p>
                      <div className="d-flex justify-content-between mb-3 align-items-center">
                        <div className="os-img">
                          <a href="https://click.google-analytics.com/redirect?tid=UA-121884158-1&amp;url=https%3A%2F%2Fitunes.apple.com%2Fin%2Fapp%2Fthinkright-me%2Fid1367849764%3Fmt%3D8&amp;aid=in.publicam.thinkrightme&amp;idfa=TR%20Website&amp;cs=TR%20Website" target="_blank" rel="noopener noreferrer"><img alt='' width="252" src="https://www.thinkrightme.com/wp-content/uploads/2022/06/ios-logo-dwnd.png" className="img-fluid"/></a>
                        </div>
                        <div className="os-img">
                          <a href="https://play.google.com/store/apps/details?id=in.publicam.thinkrightme&amp;referrer=utm_source%3DTR%2520Website" target="_blank" rel="noopener noreferrer"><img alt='' width="252" src="https://www.thinkrightme.com/wp-content/uploads/2022/06/image-77.png" className="img-fluid"/></a>
                        </div>
                      </div>
                      <div className="top-qr mb-2">
                        <img width="236" src="https://www.thinkrightme.com/wp-content/themes/thinkright-child/assets/images/qr1.png" alt='' className="img-fluid d-block mx-auto"/>
                        <p className="downl-app-text text-center mb-0">Scan and download  the app  </p>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </nav>
    </header>	
    <UnsubUserHome unsubHomeContent={unsubHomeContent}/>
    <Footer/>
    {/* END code at the time of version 4.7 changes to display stay tuned pages */}

    {/* Below code commented at the time of version 4.7 changes to display stay tuned pages */}

      {/* <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" >
          <Route index element={<Webpage configData={configData} helpUrls={helpUrls} subscriptionSource={subscriptionSource} setsubscriptionSource={setsubscriptionSource} showOfferPopUp={showOfferPopUp} handleShowOfferPopUp={handleShowOfferPopUp}  voucherPurchaseSource={voucherPurchaseSource} setvoucherPurchaseSource={setvoucherPurchaseSource}/>} />
          <Route path="dashboard" element={<Mainpage configData={configData} helpUrls={helpUrls} subscriptionSource={subscriptionSource} setsubscriptionSource={setsubscriptionSource}  isContentUnlockFreePassUser={isContentUnlockFreePassUser} searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword}  showOfferPopUp={showOfferPopUp} handleShowOfferPopUp={handleShowOfferPopUp}  voucherPurchaseSource={voucherPurchaseSource} setvoucherPurchaseSource={setvoucherPurchaseSource}/>}>
            <Route path="home"  index element={<Home />} />
            <Route path="dailyAffirmation"  element={<DailyAffirmation />} />
            <Route path="profile_details"  element={<Profile />} />
            <Route path="morningzen"  element={<MorningZen />} />
            <Route path="meditation"  element={<Meditation />} />
            <Route path="sound"  element={<Sound />} />
            <Route path="yoga"  element={<Yoga />} />
            <Route path="helpandtips"  element={<ComingSoon />} />
            <Route path="manage_subscription"  element={<Profile />} />
            <Route path="manage_subscription_payment_success"  element={<Profile />} />
            <Route path="manage_subscription_voucher_payment_success"  element={<Profile />} />
            <Route path="manage_subscription_payment_failed"  element={<Profile />} />
            <Route path="*"  element={<NotFound/>} />
            <Route path="search"  element={<GlobalSearchResult searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword}/>} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter> */}
    {/* {
      displayLoader === true ?
      <Oval
        height={80}
        width={80}
        color="#ff7259"
        wrapperStyle={{}}
        wrapperClass="processing-loader"
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#ff7259"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
      :""
    } */}
    </>
  );
}

export default App;
