import React,{useState,useRef,useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { favouriteUnFavouriteMark } from '../../../../services/Favourites';
import * as constants from "../../../../Constants";
import { confirmAlert } from 'react-confirm-alert'; // Import

//Component Description - Miniplayer modal component for sound content
export default function MiniPlayer({content, handleContentData, openMiniPlayer,handleMiniPlayer, videoModal, handleVideoModal,handleIsLibraryjourney,isUserSubscribe,setsubscriptionSource}) {
    const [playbtn,setPlaybtn] = useState(<i className="fa fa-play fa-2x"></i>);
    const [totalTime,setTotalTime] = useState('00:00');
    const [playedTime,setplayedTime] = useState('00:00');
    const [progressBar,setprogressBar] = useState('0');
    const [add_to_fav,added_to_fav]=useState('');
    const [favourite,markFavourite]=useState(0);

    let navigate = useNavigate();
    let consumedTime;
    if(content.playedTime > 0 ){
        consumedTime = content.playedTime;
    }
    let image_url;
    let content_title;
    let media_url;
    if(content.entities){
        image_url=content.entities.audio.thumb_image[0];
        content_title=content.content_title;
        media_url=content.entities.audio.media_url[0].url;
    }else{
        image_url="";
        content_title="";
        media_url="";
    }
    
    let hours = "";
    let minutes = "";
    let seconds = "";
    let totalDuration = "";
    let currentTime = "";
    let currentProgress = "";

    const audioRef = useRef();
    var x;
    useEffect(() => {
        if(content.engagement.is_favourite==="1" || content.engagement.is_favourite===1){
            added_to_fav("added_to_fav");
            markFavourite(1);
        }else{
            added_to_fav("");
            markFavourite(0);
        }
        if(audioRef.current !== null && media_url!==""){
            //progressbar seek
            window.$("#slider").slider({
                min: 0,
                max: 100,
                step: 1,
                slide: showValue
            });

            function showValue(event, ui) {
                if(audioRef.current.currentTime !== null){
                audioRef.current.currentTime = (ui.value/100)*audioRef.current.duration;
                }
            }
            setTimeout(() => {
                if(content.isPaused !== true){
                    audioRef.current.play().catch((e)=>{
                        console.log(e)
                    });
                  }
                    
                 if(consumedTime > 0){
                    audioRef.current.currentTime = consumedTime;
                  }
            }, 150);
            x = setInterval(() => {
                if(audioRef.current.paused===true){
                    setPlaybtn (<i className="fa fa-play  fa-2x"></i>);
                }else{
                    setPlaybtn (<i className="fa fa-pause  fa-2x"></i>);
                }
                hours = Math.floor(audioRef.current.currentTime / 3600);
                minutes = Math.floor((audioRef.current.currentTime - hours * 3600) / 60);
                seconds = Math.floor(audioRef.current.currentTime - (hours * 3600 + minutes * 60));
                if(hours < 10){ hours= "0" + hours }
                if(minutes < 10){ minutes= "0" + minutes }
                if(seconds < 10){ seconds= "0" + seconds }
                currentTime = hours > 0 ? hours +":"+minutes +":"+seconds : minutes +":"+seconds;
                setplayedTime(currentTime);

                currentProgress = ((audioRef.current.currentTime/audioRef.current.duration)*100).toFixed(2) + "%";
                window.$("#slider").slider("option", "value", ((audioRef.current.currentTime/audioRef.current.duration)*100).toFixed(2) );
                setprogressBar(currentProgress)

                if(totalTime==='00:00' && audioRef.current !== null){
                    hours = Math.floor(audioRef.current.duration / 3600);
                    minutes = Math.floor((audioRef.current.duration - hours * 3600) / 60);
                    seconds = Math.floor(audioRef.current.duration - (hours * 3600 + minutes * 60));
                    if(hours < 10){ hours= "0" + hours }
                    if(minutes < 10){ minutes= "0" + minutes }
                    if(seconds < 10){ seconds= "0" + seconds }
                    totalDuration = hours > 0 ? hours +":"+minutes +":"+seconds : minutes +":"+seconds;
                    setTotalTime(totalDuration);
                }
                if (audioRef.current.ended===true) {
                    clearInterval(x);
                    handleMiniPlayer(false);
                }
            }, 200);
        }
        return () => clearInterval(x);
    }, [media_url])
    
    const handlePlayPause = (e) =>{
        e.preventDefault();
        console.log(audioRef.current.paused)
        if(audioRef.current.paused===false){
            audioRef.current.pause();
            setPlaybtn(<i className="fa fa-play  fa-2x"></i>);
        }else{
            audioRef.current.play();
            setPlaybtn(<i className="fa fa-pause  fa-2x"></i>);
        }
    }
    const togglePlayer = (e) =>{
        e.preventDefault();
        content.playedTime = audioRef.current.currentTime;
        content.engagement.is_favourite = favourite;
        content.isPaused=audioRef.current.paused;
        handleContentData(content);
        handleVideoModal(true);
        handleMiniPlayer(false);
    }
    const closeMiniPlayer = (e) =>{
        e.preventDefault();
        handleMiniPlayer(false);
    }
    const goBackward = (e) =>{
        e.preventDefault();
        audioRef.current.currentTime = audioRef.current.currentTime - 10;
    }
    
    const goForward = (e) =>{
        e.preventDefault();
        audioRef.current.currentTime = audioRef.current.currentTime + 10;
    }

    window.onkeydown = function(e) {
        //keyboard key press action on left/right arrow and space key
        if(handleVideoModal===true){
          switch (e.keyCode) {
              case 37:
                goBackward(e);
                  // alert('left');
                break;
              case 39:
                goForward(e);
                  // alert('right');
                break;
              case 32:
                handlePlayPause(e);
                  // alert('right');
                break;    
          }
        }
      };

      const favouriteToggle = (e) => {
        e.preventDefault();
    
        if(isUserSubscribe === false){
          handleMiniPlayer(false);
          setsubscriptionSource(content.page);
          navigate(`/dashboard/manage_subscription`);
          return true;
        }
    
        var favouriteContentType=content.favouriteContentType ;
    
        let data = {
            userCode : localStorage.getItem('userCode'),
            superStoreId : content.super_store_id,
            storeId  : content.store_id,
            pageId   : content.page_id,
            portletId : content.portlet_id,
            contentId: content.id,
            markFavourite : (favourite===0) ? 1:0,
            favouriteContentType : favouriteContentType,
            locale : constants.LOCALE
        }
    
        if(data.markFavourite === 0){
            confirmAlert({
                // title: 'Confirm to submit',
                message: 'Do you want remove this from favourite list?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => FavouriteUnfavourite(data)
                    },
                    {
                        label: 'No',
                    }
                ],
                overlayClassName:"zindex9999"
            });
        }else{
            FavouriteUnfavourite(data);
        }
    };
    
    function FavouriteUnfavourite(payload){
        favouriteUnFavouriteMark(payload).then(response => {
            if(response.code === 200){
                if (add_to_fav!=="") {
                    added_to_fav("");
                    handleIsLibraryjourney(true);
                } else {
                    added_to_fav("added_to_fav");
                    handleIsLibraryjourney(true);
                }
    
                if(favourite!==0){
                    window.$("#favbox").fadeOut();
                    window.$("#unfavbox").fadeIn();
                    markFavourite(0);
    
                    setTimeout(function(){
                        window.$("#unfavbox").fadeOut();
                    },5000);
                }else{
                    window.$("#unfavbox").fadeOut();
                    window.$("#favbox").fadeIn();
                    markFavourite(1);
    
                    setTimeout(function(){
                        window.$("#favbox").fadeOut();
                    },5000);
                }
            }else{
                window.$("#unfavbox").fadeOut();
                window.$("#favbox").fadeOut();
                window.$("#error-message").fadeIn();
                window.$("#errortitle").html('');
                window.$("#errordesc").html(response.message);
                setTimeout(function(){
                    window.$("#error-message").fadeOut();
                },5000);
            }
        });
    }
  return (
    
    <div className='miniplayer-modal width480' style={{zIndex:"9999"}}>  
        <div className="mini-player-section">
            <div className="img-overlay"></div>
            <img src={image_url} className="img-responsive media-player-banner-img" style={{aspectRatio:9/8}}/>
            <div className="player media-details-section">
                <h4 className="music-title">{content_title}</h4>
                    <div className="audio-music-details-section slider_container">
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <small className="current-duration current-time">{playedTime}</small>
                            <small className="content-duration total-duration">{totalTime !=="NaN:NaN" ? totalTime : "00:00"}</small>
                        </div>
                        <div className="progress" id='slider' style={{"cursor": "pointer",height:"2px",border:"unset"}}>
                        <div className="progress-bar"  style={{"width":progressBar,backgroundColor:"#FF7259"}} role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div style={{width:"100%", margin:"auto", position:"relative"}}>
                            <audio id="contentAudioMusic" ref={audioRef} src={media_url} controls style={{width:"100%"}}>
                                {/* <source src={media_url}/>This browser does not support the audio tag */}
                            </audio>
                        </div>
                    </div>
                    <div className="media-controls">
                        <div className="buttons d-flex justify-content-between" style={{width:"40%",margin:"0 auto"}}>
                            <a href='' className="align-self-center" onClick={(e)=>goBackward(e)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 50 50" fill="none" style={{"color":"red"}}>
                                <path d="M12.1417 14.5833H27.0833C30.3985 14.5833 33.578 15.9003 35.9222 18.2445C38.2664 20.5887 39.5833 23.7681 39.5833 27.0833C39.5833 30.3985 38.2664 33.578 35.9222 35.9222C33.578 38.2664 30.3985 39.5833 27.0833 39.5833H8.33333V43.75H27.0833C31.5036 43.75 35.7428 41.9941 38.8684 38.8684C41.9941 35.7428 43.75 31.5036 43.75 27.0833C43.75 22.6631 41.9941 18.4238 38.8684 15.2982C35.7428 12.1726 31.5036 10.4167 27.0833 10.4167H12.1417L17.425 5.13333L14.4792 2.1875L4.16666 12.5L14.4792 22.8125L17.425 19.8667L12.1417 14.5833Z" fill="white"/>
                                <path d="M23.012 30.752C23.492 30.752 23.732 30.956 23.732 31.364C23.732 31.788 23.492 32 23.012 32H18.956C18.468 32 18.224 31.788 18.224 31.364C18.224 30.956 18.468 30.752 18.956 30.752H20.204V25.28L19.052 25.988C18.94 26.052 18.84 26.084 18.752 26.084C18.584 26.084 18.44 26.012 18.32 25.868C18.208 25.724 18.152 25.564 18.152 25.388C18.152 25.156 18.256 24.976 18.464 24.848L20.324 23.696C20.564 23.552 20.792 23.48 21.008 23.48C21.232 23.48 21.412 23.548 21.548 23.684C21.684 23.82 21.752 24.008 21.752 24.248V30.752H23.012ZM27.7953 32.108C26.7953 32.108 26.0313 31.74 25.5033 31.004C24.9753 30.26 24.7113 29.18 24.7113 27.764C24.7113 26.356 24.9753 25.284 25.5033 24.548C26.0313 23.804 26.7953 23.432 27.7953 23.432C28.7953 23.432 29.5593 23.804 30.0873 24.548C30.6153 25.284 30.8793 26.356 30.8793 27.764C30.8793 29.172 30.6153 30.248 30.0873 30.992C29.5593 31.736 28.7953 32.108 27.7953 32.108ZM27.7953 30.896C28.3393 30.896 28.7353 30.648 28.9833 30.152C29.2313 29.648 29.3553 28.852 29.3553 27.764C29.3553 26.668 29.2313 25.876 28.9833 25.388C28.7353 24.892 28.3393 24.644 27.7953 24.644C27.2513 24.644 26.8553 24.892 26.6073 25.388C26.3593 25.884 26.2353 26.676 26.2353 27.764C26.2353 28.852 26.3593 29.648 26.6073 30.152C26.8553 30.648 27.2513 30.896 27.7953 30.896Z" fill="white"/>
                                </svg>
                            </a>
                            <a href='' className="player-btn round-btn-play" onClick={(e)=>handlePlayPause(e)} id="playpause_button" style={{width:"70px",height:"70px",margin: "0 auto"}} >
                            {playbtn}</a>
                            <a href='' className="align-self-center" onClick={(e)=>goForward(e)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 50 50" fill="none" style={{"color":"red"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                <path d="M37.8583 14.5833H22.9167C19.6015 14.5833 16.422 15.9003 14.0778 18.2445C11.7336 20.5887 10.4167 23.7681 10.4167 27.0833C10.4167 30.3985 11.7336 33.578 14.0778 35.9222C16.422 38.2664 19.6015 39.5833 22.9167 39.5833H41.6667V43.75H22.9167C18.4964 43.75 14.2572 41.9941 11.1316 38.8684C8.00595 35.7428 6.25 31.5036 6.25 27.0833C6.25 22.6631 8.00595 18.4238 11.1316 15.2982C14.2572 12.1726 18.4964 10.4167 22.9167 10.4167H37.8583L32.575 5.13333L35.5208 2.1875L45.8333 12.5L35.5208 22.8125L32.575 19.8667L37.8583 14.5833Z" fill="white"/>
                                <path d="M21.012 30.752C21.492 30.752 21.732 30.956 21.732 31.364C21.732 31.788 21.492 32 21.012 32H16.956C16.468 32 16.224 31.788 16.224 31.364C16.224 30.956 16.468 30.752 16.956 30.752H18.204V25.28L17.052 25.988C16.94 26.052 16.84 26.084 16.752 26.084C16.584 26.084 16.44 26.012 16.32 25.868C16.208 25.724 16.152 25.564 16.152 25.388C16.152 25.156 16.256 24.976 16.464 24.848L18.324 23.696C18.564 23.552 18.792 23.48 19.008 23.48C19.232 23.48 19.412 23.548 19.548 23.684C19.684 23.82 19.752 24.008 19.752 24.248V30.752H21.012ZM25.7953 32.108C24.7953 32.108 24.0313 31.74 23.5033 31.004C22.9753 30.26 22.7113 29.18 22.7113 27.764C22.7113 26.356 22.9753 25.284 23.5033 24.548C24.0313 23.804 24.7953 23.432 25.7953 23.432C26.7953 23.432 27.5593 23.804 28.0873 24.548C28.6153 25.284 28.8793 26.356 28.8793 27.764C28.8793 29.172 28.6153 30.248 28.0873 30.992C27.5593 31.736 26.7953 32.108 25.7953 32.108ZM25.7953 30.896C26.3393 30.896 26.7353 30.648 26.9833 30.152C27.2313 29.648 27.3553 28.852 27.3553 27.764C27.3553 26.668 27.2313 25.876 26.9833 25.388C26.7353 24.892 26.3393 24.644 25.7953 24.644C25.2513 24.644 24.8553 24.892 24.6073 25.388C24.3593 25.884 24.2353 26.676 24.2353 27.764C24.2353 28.852 24.3593 29.648 24.6073 30.152C24.8553 30.648 25.2513 30.896 25.7953 30.896Z" fill="white"/>
                                </svg>
                                </svg>
                            </a>
                        </div>
                    </div>
            </div>
        </div>
        <a href="#" class="fav-btn" onClick={(e)=>favouriteToggle(e)} style={{top:"10px",left:"10px",right:"unset"}}>
            <i class={`fas fa-heart ${add_to_fav}`}></i>
        </a> 
        <a href="#" class="fav-btn" onClick={(e)=>closeMiniPlayer(e)} style={{top:"10px",background:"unset"}}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" fill="rgba(255,255,255,1)"/></svg>
        </a>
        <a href="#" class="expand-btn" title='Go to Player' onClick={(e)=>togglePlayer(e)}>
            <img src="https://img.icons8.com/ios-filled/24/ffffff/decompress.png"/>
        </a>
        
     </div>
  )
}
