import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

//Component Description - Link Sharing modal on social media
export default function ShareSocial({openSocialShare, handleSocialShare, shareDetails}) {

  const copyToClipboard = (e) => {
    e.preventDefault();
    var copyText = document.getElementById("copyval");
    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
     // Copy the text inside the text field
    var shareMsg  = shareDetails.desc; 
    var shareText = shareMsg+"\n"+copyText.value;
    navigator.clipboard.writeText(shareText);
    window.$("#copy-msg").html('Text copied to clipboard!');
    setTimeout(function(){
        window.$("#copy-msg").html('');
    },5000);
  }
 
  //share on whatsapp details
  let whatsapp = "https://web.whatsapp.com/send?text="+shareDetails.desc+" "+encodeURIComponent(shareDetails.link);
  //share on telegram details
  let telegram = "https://telegram.me/share/url?url="+encodeURIComponent(shareDetails.link)+"&text="+shareDetails.desc;
  //share on mail details
  let mail ="mailto:?subject=Check this out!&body="+shareDetails.desc+" "+encodeURIComponent(shareDetails.link);

  return (
    <Modal
    show={openSocialShare}
    onHide={() => handleSocialShare(false)}
    id="share-social" 
     tabIndex="-1" 
     aria-labelledby="share-s" 
     aria-hidden="true"
    >
        <div className="modal-header pb-3">
          <h5 className="modal-title" id="share-s">Share</h5>
          <button type="button" className="btn-close" onClick={() => handleSocialShare(false)} data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body ">   
            <ul className="list-unstyled list-inline mb-3">
            <li className="list-inline-item"><a href={whatsapp} target="_blank"><img width="50" src="../dashboard-assets/images/WhatsApp.svg"/></a></li>
            <li className="list-inline-item"><a href={telegram} target="_blank"><img width="50" src="../dashboard-assets/images/Telegram.svg"/></a></li>
            <li className="list-inline-item"><a href={mail} target="_blank"><img width="50" src="../dashboard-assets/images/email.svg"/></a></li>
            </ul>
            <div className="copyurl position-relative mb-5">
            <input type="text" name="" className="form-control copyval" id="copyval" value={shareDetails.link} readOnly/>
            <Link  className="copybtn" onClick={(e)=>copyToClipboard(e)} style={{right:"12px",backgroundColor:"#fff"}}>Copy</Link>
            <div id="copied-success" className="copied">
                <span>Copied!</span>
            </div>
            </div>
            <p id='copy-msg' className='linkcopied-msg'></p>  
        </div>
    </Modal>
          
  )
}
