import * as config from "./Config";

//export const API_URL 		= "http://192.168.1.173:8285/";
export const BASE_URL = config.BASE_URL;
export const API_URL = config.API_URL;
export const SUPER_STORE_ID = config.SUPER_STORE_ID;
export const PLATFORM 		= "web";
// export const PLATFORM 		= "android"; //initial developmental purpose for store api
export const VERSION 		= config.VERSION;
export const LANGUAGE 		= "en";
export const SEGMENT 		= "";
export const DEFAULT_COUNTRY = "IN";

export const LOCALE         = {country: DEFAULT_COUNTRY , platform: PLATFORM, version:  VERSION, language: LANGUAGE, segment:  ""};

export const PACKAGE_NAME = config.PACKAGE_NAME;

//export system date
const today = new Date();
const yyyy = today.getFullYear();
let mm = today.getMonth() + 1; // Months start at 0!
let dd = today.getDate();
let hr = today.getHours();
let mn = today.getMinutes();
let sc = today.getSeconds();

if (dd < 10) dd = '0' + dd;
if (mm < 10) mm = '0' + mm;

export const SYSTEM_DATE = dd + '-' + mm + '-' + yyyy;
// export const SYSTEM_DATE = "23-09-2022";

export const SYSTEM_DATETIME = yyyy + '-' + mm + '-' + dd + ' ' + hr + ':' + mn + ':' + sc;
// export const SYSTEM_DATETIME = '2022-05-30 ' + hr + ':' + mn + ':' + sc;
export const TEST_USERCODE = config.TEST_USERCODE;

//google recaptcha site key
export const SITE_KEY = config.SITE_KEY;