//import constants
import * as constants from "../Constants";

export async function getUserProfileDetails(data){

	const response = await fetch(constants.API_URL+`v3/profile/getUserProfileDetails_1_0` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}

export async function getStatesList(data){

	const response = await fetch(constants.API_URL+`v3/profile/getStatesList_1_0` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}

export async function updateUserProfileDetails(data){

	const response = await fetch(constants.API_URL+`v3/profile/updateProfile_1_1` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}