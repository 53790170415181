//import constants
import * as constants from "../Constants";

export async function getCountryCode(){
	const response = await fetch(constants.API_URL+`v3/app/countryList_1_0` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
	})
	return await response.json();
}

export async function getKeyValueStrings(data){
	const response = await fetch(constants.API_URL+`v3/KeyValueStrings/getKeyValueStrings_1_0` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}