//Utitlity functions for application
export function date_convert(date) {
    let d = new Date(date);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return da + ' ' + mo + '. ' + ye;
}

export function getContentDateTimestamp(date){
    var d = new Date(date);
    return d.getTime();
}

export function getContentDateTime(date){
    var d = new Date(date);
    var dt = d.getDate();
    var mn = d.getMonth()+1;
    var yr = d.getFullYear();
    var newDt = new Date(yr+'-'+mn+'-'+dt);
    return newDt.getTime();
}

export function contentDate_convert(date) {
    let d = new Date(date);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

    return da + '-' + mo + '-' + ye;
}

export function formatAMPM(dateTime) { 
    var date = new Date(dateTime);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  export function datediff(first, second) {
    
    var date1 = first*1000;
    var date2 = new Date(second).getTime();
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((date2-date1)/(1000*60*60*24));
}