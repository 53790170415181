import * as  constants from '../Constants';

export async function userPassbookData(data){
    const response = await fetch(constants.API_URL+`v3/voucher/userPassbookData_1_0`,{
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(data) 
    })

    return await response.json();
}

export async function getVoucherPlans(data){
    const response = await fetch(constants.API_URL+`v3/voucher/getVoucherPlans_1_3`,{
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(data)
    })

    return await response.json();
}

export async function initiateVoucherPurchaseTxn(data){
    const response = await fetch(constants.API_URL+`v3/inApp/getPaymentChannelInfo_1_1`,{
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(data)
    })

    return await response.json();
}

export async function getTransactionVoucherData(data){
    const response = await fetch(constants.API_URL+`v3/voucher/transactionVoucherData_1_0`,{
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(data)
    })

    return await response.json();
}

export async function shareGiftVoucher(data){
    const response = await fetch(constants.API_URL+`v3/voucher/voucherAppShare_1_0`,{
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(data)
    })

    return await response.json();
}



