import * as constants from "../Constants";

export async function getUserBase(data){
    const response = await fetch(constants.API_URL+`v3/user/getUserBase` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}

export async function getUserFreePass(data){
    const response = await fetch(constants.API_URL+`v3/userPreferences/getUserFreePass_1_0` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}

export async function updateUserVisit(data){
	const response = await fetch(constants.API_URL+`v3/login/logAndGetUserStatus_1_0` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}