import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

//Component Description - Warmp up screen component for meditation
export default function PlayerTimer({content,detailModal,handleContentDetail,timerModal,handleTimerModal,videoModal,handleVideoModal,warmUpTime,handleWarmUpTime}) {
    const [intervl, setintervl]  = useState(true);
    let poster_url = "";
    if(content.content_type==="news" && content.entities.news_articles[0].media_url!==null){
        poster_url = content.entities.news_articles[0].media_thumb_url;
    }else if(content.content_type==="audio"){
        poster_url = content.entities.audio.thumb_image[0]
      }else if(content.content_type==="video"){
        poster_url = content.entities.video.thumb_image[0]
      }else{
        poster_url = content.entities.image.thumb_image[0];
    }
    var timeRemain = warmUpTime ;

    useEffect(() => {
        const x = setInterval(function() {
            document.getElementById("timer").innerHTML = timeRemain + " sec";
            timeRemain--;
            if (timeRemain < 0 && intervl===true) {
                clearInterval(x);
                handleWarmUpTime('');
                handleContentDetail(false)
                handleVideoModal(true);
                handleTimerModal(false);
                clearInterval(x);
            }else if(intervl===false){
                clearInterval(x)
                handleTimerModal(false)
            }
        },1000)
      return () =>  clearInterval(x)
    }, [intervl])
    
    const closeModal =(e) =>{
        e.preventDefault();
        setintervl(false);
    }
  
    return (
    <Modal
    show={timerModal}
    onHide={(e) => closeModal(e)}
    dialogClassName=" modal-fullscreen"
    contentClassName="height-100pc"
    tabIndex="-1"
    aria-labelledby="video-popups"
    aria-hidden="true"
    id="video-player"
    > 
        <Link onClick={(e) => closeModal(e)} data-bs-dismiss="modal" aria-label="Close" className="backbtn"><i className="fas fa-angle-left"></i></Link>
        <div className="modal-body p-0">
            <video width="100%" height="100%" poster={poster_url}>
                <source src="" type="video/mp4"/>
            </video>
            <div className="abs-start-vid">
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="video-settle">
                        <p className='txt-shadow'>Please settle down yourself audio/Video will be starting in </p>
                        <h2 className='txt-shadow' id="timer"></h2>
                    </div>
                </div>
            </div>
        </div> 
    </Modal>
  )
}
