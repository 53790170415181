import React, { useEffect,useRef,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TrustedUsersSection from '../landing-component/TrustedUsersSection';
import TestimonialSlider from '../landing-component/TestimonialSlider';

//Component Description - unsub userbased landing page with trending api based single free content player
export default function UnsubUserHome({signupBlockModal,setSignupBlockModal,unsubHomeContent,offerBanner,setsubscriptionSource,showOfferPopUp,handleShowOfferPopUp}) {
    const [playbtn,setPlaybtn] = useState(<i className="fa fa-play fa-2x"></i>);
    const [totalTime,setTotalTime] = useState('00:00');
    const [playedTime,setplayedTime] = useState('00:00');
    const [progressBar,setprogressBar] = useState('0');

    // Commented at the time of version 4.7 changes to display stay tuned pages
    // let navigate = useNavigate();
    let image_url;
    let singer;
    let content_title;
    let media_url;
    if(unsubHomeContent.entities){
        image_url=unsubHomeContent.entities.audio.thumb_image[0];
        singer=unsubHomeContent.metadata.singer[0];
        content_title=unsubHomeContent.content_title;
        media_url=unsubHomeContent.entities.audio.media_url[0].url;
    }else{
        image_url="";
        singer="";
        content_title="";
        media_url="";
    }
    let hours = "";
    let minutes = "";
    let seconds = "";
    let totalDuration = "";
    let currentTime = "";
    let currentProgress = "";

    const audioRef = useRef();
    var x;
    useEffect(() => {
        if(audioRef.current !== null && media_url!==""){
            //progressbar seek
            window.$("#slider").slider({
                min: 0,
                max: 100,
                step: 1,
                slide: showValue
            });

            function showValue(event, ui) {
                if(audioRef.current.currentTime !== null){
                audioRef.current.currentTime = (ui.value/100)*audioRef.current.duration;
                }
            }

            x = setInterval(() => {
                if(audioRef.current.paused===true){
                    setPlaybtn (<i className="fa fa-play  fa-2x"></i>);
                }else{
                    setPlaybtn (<i className="fa fa-pause  fa-2x"></i>);
                }
                hours = Math.floor(audioRef.current.currentTime / 3600);
                minutes = Math.floor((audioRef.current.currentTime - hours * 3600) / 60);
                seconds = Math.floor(audioRef.current.currentTime - (hours * 3600 + minutes * 60));
                if(hours < 10){ hours= "0" + hours }
                if(minutes < 10){ minutes= "0" + minutes }
                if(seconds < 10){ seconds= "0" + seconds }
                currentTime = hours > 0 ? hours +":"+minutes +":"+seconds : minutes +":"+seconds;
                setplayedTime(currentTime);
                currentProgress = ((audioRef.current.currentTime/audioRef.current.duration)*100).toFixed(2) + "%";
                window.$("#slider").slider("option", "value", ((audioRef.current.currentTime/audioRef.current.duration)*100).toFixed(2) );
                setprogressBar(currentProgress)

                if(totalTime==='00:00' && audioRef.current !== null){
                    hours = Math.floor(audioRef.current.duration / 3600);
                    minutes = Math.floor((audioRef.current.duration - hours * 3600) / 60);
                    seconds = Math.floor(audioRef.current.duration - (hours * 3600 + minutes * 60));
                    if(hours < 10){ hours= "0" + hours }
                    if(minutes < 10){ minutes= "0" + minutes }
                    if(seconds < 10){ seconds= "0" + seconds }
                    totalDuration = hours > 0 ? hours +":"+minutes +":"+seconds : minutes +":"+seconds;
                    setTotalTime(totalDuration);
                }
                if (audioRef.current.ended===true) {
                    setplayedTime('00:00');
                    setprogressBar("0%");
                }
            }, 200);
        }
        return () => clearInterval(x);
    }, [media_url])
    
    const handlePlayPause = (e) =>{
        e.preventDefault();
        if (audioRef.current.duration > 0) {
            if(audioRef.current.paused===false){
                audioRef.current.pause();
                setPlaybtn(<i className="fa fa-play  fa-2x"></i>);
            }else{
                audioRef.current.play();
                setPlaybtn(<i className="fa fa-pause  fa-2x"></i>);
            }
        }
    }
    const checkLoggedIn=(e)=>{
        e.preventDefault();
        if(audioRef.current.paused===false){
            audioRef.current.pause();
            setPlaybtn(<i className="fa fa-play  fa-2x"></i>);
            audioRef.current.currentTime=0;
        }
        if(localStorage.getItem('isLoggedIn') && localStorage.getItem('isLoggedIn') === "true"){
            setSignupBlockModal(false);
            setsubscriptionSource("homeOfferBanner")

            // Commented at the time of version 4.7 changes to display stay tuned pages
            // navigate(`/dashboard/manage_subscription`)
        }else{
            
            console.log("unsub user");
            setSignupBlockModal(true);
        }
    } 
    window.onkeydown = function(e) {
          switch (e.keyCode) {
              case 32:
                handlePlayPause(e);
                  // alert('right');
                break;    
          }
      };
    function applyOffers(e){
        e.preventDefault();
        if(audioRef.current.paused===false){
            audioRef.current.pause();
            setPlaybtn(<i className="fa fa-play  fa-2x"></i>);
            audioRef.current.currentTime=0;
        }
        if(localStorage.getItem('isLoggedIn') && localStorage.getItem('isLoggedIn') === "true"){
            setSignupBlockModal(false);
            handleShowOfferPopUp(true)
            setsubscriptionSource("homeOfferBanner")

            // Commented at the time of version 4.7 changes to display stay tuned pages
            // navigate(`/dashboard/manage_subscription`)
        }else{
            setSignupBlockModal(true);
        }
        
    }
    
    if(localStorage.getItem('reload') && localStorage.getItem('reload') === "true"){
        setSignupBlockModal(true);
    }
 
  return (
    <>
    <div className="container-fluid web-app-homepage-banner-section">
        <div className="row col-lg-12 col-md-12 homepage-banner-row nopadding">
            <div className="col-lg-6 col-md-6 homepage-banner-left-content-sec">
              {/* <h1 className="banner-title-sub-user">
              To change the way you live, you must change the way you think.
              </h1> */}
               <h1 className="banner-title-sub-user">
              Namaste!
              </h1>
              <div className="banner-desc-content">
              {/* <p>Pause, reflect and connect with your inner self. Create your own meditation & mindfulness routine guided by your emotional needs.</p> */}
              <p>We're enhancing our web app for a better experience. Stay tuned for the new and improved version.</p>
                <p>For an upgraded user experience, log in to your account on the Think Right app</p>
              </div>
              {/* <a href='' onClick={(e)=>checkLoggedIn(e)} className="banner-button-link"><div className="homepage-banner-btn">Get Started</div></a>
              <h4 className="banner-btn-caption">Start the journey of transformation</h4> */}
            </div>
            <div className="col-lg-6 col-md-6 nopadding ">
                {/* <div className="banner-img-media-player-section">
                    <div className="img-overlay"></div>
                    <img src={image_url} className="img-responsive media-player-banner-img" style={{aspectRatio:9/8}}/>
                    <div className="player media-details-section">
                        <h4 className="music-title">{content_title}</h4>
                        <p className="music-artist-name">{singer}</p>
                            <div className="audio-music-details-section slider_container">
                                <div className="d-flex justify-content-between align-items-center mb-1">
                                    <small className="current-duration current-time">{playedTime}</small>
                                    <small className="content-duration total-duration">{totalTime !=="NaN:NaN" ? totalTime : "00:00"}</small>
                                </div>
                                <div className="progress mb-3" id='slider' style={{"cursor": "pointer",height:"2px",border:"unset"}}>
                                <div className="progress-bar"  style={{"width":progressBar,backgroundColor:"#FF7259"}} role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div style={{width:"100%", margin:"auto", position:"relative"}}>
                                    <audio id="contentAudioMusic" ref={audioRef} src={media_url} controls style={{width:"100%"}}>
                                        <source src={media_url}/>This browser does not support the audio tag
                                    </audio>
                                </div>
                            </div>
                            <div className="media-controls">
                                <div className="buttons">
                                    <a href='' className="player-btn round-btn-play" onClick={(e)=>handlePlayPause(e)} id="playpause_button" style={{width:"70px",height:"70px",margin: "0 auto"}} >
                                    {playbtn}</a>
                                </div>
                            </div>
                    </div>
                </div> */}
                <audio id="contentAudioMusic" ref={audioRef} src={media_url} controls style={{width:"100%"}}>
                    {/* <source src={media_url}/>This browser does not support the audio tag */}
                </audio>
                <div className="p-3 download-app-section">
                    {/* <p className="downl-app-text">Download  The App </p> */}
                    <div className="d-flex justify-content-center mb-3 align-items-center gap-4">
                      <div className="os-img">
                        <a href="https://click.google-analytics.com/redirect?tid=UA-121884158-1&amp;url=https%3A%2F%2Fitunes.apple.com%2Fin%2Fapp%2Fthinkright-me%2Fid1367849764%3Fmt%3D8&amp;aid=in.publicam.thinkrightme&amp;idfa=TR%20Website&amp;cs=TR%20Website" target="_blank" rel="noopener noreferrer">
                            <img alt='' width="160" src="https://www.thinkrightme.com/wp-content/uploads/2022/06/ios-logo-dwnd.png" className="img-fluid"/></a>
                      </div>
                      <div className="os-img">
                        <a href="https://play.google.com/store/apps/details?id=in.publicam.thinkrightme&amp;referrer=utm_source%3DTR%2520Website" target="_blank" rel="noopener noreferrer">
                            <img alt='' width="170" src="https://www.thinkrightme.com/wp-content/uploads/2022/06/image-77.png" className="img-fluid"/></a>
                      </div>
                    </div>
                    <div className="top-qr mb-2">
                      <img width="280" src="https://www.thinkrightme.com/wp-content/themes/thinkright-child/assets/images/qr1.png" alt='' className="img-fluid d-block mx-auto"/>
                      <p className="downl-app-text text-center mb-0">Scan and download  the app  </p>
                    </div>
                  </div>
            </div>
        </div>
    </div>
    
    <div className="container benefits-section">
        <h2 className="benefits-section-title">Benefits of ThinkRight Web App</h2>
        <div className="benefits-list-content" style={{backgroundImage:"./assets/images/benefits-of-thinkright-web-app-img.png"}}>
            <img src="./assets/images/benefits-of-thinkright-web-app-img.png" className="benefit-img img-responsive"/>
        </div>
    </div>

    <div className="container offer-coupon-code-section">
        <a href="#"  onClick={ (e) => applyOffers(e)}><img src={offerBanner} className="img-responsive coupan-code-img" style={{maxWidth: "720px"}} /></a>
        {/* <a href="#"  onClick={ (e) => applyOffers(e)}><img src={offerBanner} className="img-responsive coupan-code-img" style={{maxHeight: "360px"}}/></a> */}
    </div>

    <div className="container pt-30">
        <h2 className="section-title">Why ThinkRight.Me</h2>
        <p className="section-title-desc">Meet the members of the ThinkRight.Me community.</p>     
    </div>
    
    <TestimonialSlider/>
    <TrustedUsersSection/>

    </>
  )
}
