import * as constants from "../Constants";

export async function getAppConfig(data){
    const response = await fetch(constants.API_URL+`v3/app/start_1_0`,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data),
		mode: 'cors'
	})
	return await response.json();
}

export async function getWebAppLocaleVersion(data){
    const response = await fetch(constants.API_URL+`v3/app/getWebAppLocaleVersion`,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data),
		mode: 'cors'
	})
	return await response.json();
}

