import React,{useState} from 'react'
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestimonialVideoModal from './TestimonialVideoModal';
import * as constants from "../../../Constants";

let playIcon = constants.BASE_URL+"/assets/images/play-icon.svg";
let testimonialOne = constants.BASE_URL+"/assets/images/testimonial-1.png";
let testimonialTwo = constants.BASE_URL+"/assets/images/testimonial-2.png";
let testimonialThree = constants.BASE_URL+"/assets/images/testimonial-3.png";
let birindar = constants.BASE_URL+"/assets/images/Birindar.png";
let gaurav = constants.BASE_URL+"/assets/images/gaurav.jpg";
let ashishJoshi = constants.BASE_URL+"/assets/images/ashish-joshi.jpg";
let neelima = constants.BASE_URL+"/assets/images/neelima.png";
let raksha = constants.BASE_URL+"/assets/images/raksha.png";
let heena = constants.BASE_URL+"/assets/images/heena.png";

//Component Description - testimonial/iframe youtube player slider component for userbased landing pages
export default function TestimonialSlider() {
    const [src,setSrc]=useState('');
    const [iframeModal,setIframeModal]=useState(0);

    window.$(".testimonial-cart").on('click', 'a', function(e){
        e.preventDefault();
        console.log("clicked");
        var videoId = window.$(this).attr('data-id');
        window.$('#videoIFrame').prop('src', 'https://www.youtube.com/embed/'+videoId);
    });
    window.$("#videoPopup").on('click', '.btn-close', function(e){
        console.log("close clicked");
        window.$('#videoIFrame').prop('src', ' ');
    });
    let path_url='https://www.youtube.com/embed/';
    
    var settings = {
        className:"testimonial-slick-slider slick",
        slidesToShow: 3,
        initialSlide: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
            breakpoint: 768,
            settings: {
                arrows: true,
                centerMode: false,
                centerPadding: '40px',
                slidesToShow: 3
            }
            },
            {
            breakpoint: 480,
            settings: {
                arrows: true,
                centerMode: false,
                centerPadding: '40px',
                slidesToShow: 1
            }
            }
        ]
    };
    const playTestimonial = (e,src) =>{
        e.preventDefault();
        setSrc(path_url+src);
        setIframeModal(true);
    }
  return (
    <>
        <div className="container testimonial-slider-section pb-30" style={{display:"block"}}>
            <Slider {...settings}>
                <div>
                    <div className="testimonial-cart">
                        <a href="#" onClick={(e)=>playTestimonial(e,"NT31LOCFlqk")} data-id="NT31LOCFlqk" data-bs-toggle="modal" data-bs-target="#videoPopup">
                            <div className="cart-img">
                                <img src={testimonialOne} />
                                <div className="play-btn">
                                    <img src={playIcon} />
                                </div>
                            </div>
                        </a>
                        <h4 className="authorname">Bella | <span className="degignt">Writer</span></h4>
                        {/* <div className="star-rating mb-3">
                            <img src="./assets/images/star-rating-img.svg" />
                        </div> */}
                        <div className="star-rating mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg>
                        </div>
                        <p>“The Daily Journey on the app has been the most useful for me. Every day I wake up and do the morning affirmation. Whenever I feel lost throughout the day, I go back and listen to the affirmations and it grounds me.” - Bella </p>
                    </div>
                </div>
                <div>
                    <div className="testimonial-cart">
                        <a href="#" onClick={(e)=>playTestimonial(e,"6pLCxkgYup4")} data-id="6pLCxkgYup4" data-bs-toggle="modal" data-bs-target="#videoPopup">
                            <div className="cart-img">
                                <img src={testimonialTwo} />
                                <div className="play-btn">
                                    <img src={playIcon}/>
                                </div>
                            </div>
                        </a>
                        <h4 className="authorname">Mandeep Borah | <span className="degignt">Advisor</span></h4>
                        <div className="star-rating mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                                </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                                </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                                </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                                </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg>
                        </div>                
                        <p>“With ThinkRight.Me, I used positive thinking through meditation which calmed me down and helped me with my stress and anxiety. It also helped me attain a good work-life balance.” - Mandeep </p>

                    </div>
                </div>
                <div>
                    <div className="testimonial-cart">
                        <a href="#" onClick={(e)=>playTestimonial(e,"02A5U_qFf_U")} data-id="02A5U_qFf_U" data-bs-toggle="modal" data-bs-target="#videoPopup">
                            <div className="cart-img">
                                <img src={testimonialThree} />
                                <div className="play-btn">
                                    <img src={playIcon} />
                                </div>
                            </div>
                        </a>
                        <h4 className="authorname">Melanie | <span className="degignt">Event Manager</span></h4>
                        <div className="star-rating mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.52901 14.4083L3.96375 17.5235L5.20652 11.2678L0.523438 6.93744L6.85724 6.18625L9.52901 0.394531L12.2008 6.18625L18.5346 6.93744L13.8515 11.2678L15.0943 17.5235L9.52901 14.4083Z" fill="#B4B4B4"/>
                            </svg>
                        </div>                
                        <p>“Between trying to run my business and prepping for MBA exams, I was burning myself out. After my friend gave me the ThinkRight.Me app, I started a mindfulness practice through meditation and affirmations and I’ve never looked back.” - Melanie </p>
                    </div>
                </div>
                <div>
                    <div className="testimonial-cart">
                        <a href="#" onClick={(e)=>playTestimonial(e,"AVU6o4JoTi0")} data-id="AVU6o4JoTi0" data-bs-toggle="modal" data-bs-target="#videoPopup">
                            <div className="cart-img">
                                <img src={birindar} />
                                <div className="play-btn">
                                    <img src={playIcon} />
                                </div>
                            </div>
                        </a>
                        <h4 className="authorname">Birindar | <span className="degignt">IT Professional</span></h4>
                        <div className="star-rating mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="19px" height="18px" viewBox="0 0 32 32">
                            <defs>
                            <linearGradient id="grad">
                            <stop offset="50%" stop-color="#FFA500"/>
                            <stop offset="50%" stop-color="grey"/>
                            </linearGradient>
                            </defs>
                            <path fill="url(#grad)" d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118
                            l11.547-1.2L16.026,0.6L20.388,10.918z"/>
                            </svg>
                        </div>                
                        <p>“My wife meditates to destress and she recommended the ThinkRight.Me app to me. As a novice, I found the meditations simple, useful, and relatable, it’s become my go-to for a variety of situations. I favourite them to revisit during tough times.” - Birindar</p>
                    </div>
                </div>
                <div>
                    <div className="testimonial-cart">
                        <a href="#" onClick={(e)=>playTestimonial(e,"GUxwzoQCE2k")} data-id="GUxwzoQCE2k" data-bs-toggle="modal" data-bs-target="#videoPopup">
                            <div className="cart-img">
                                <img src={gaurav} />
                                <div className="play-btn">
                                    <img src={playIcon} />
                                </div>
                            </div>
                        </a>
                        <h4 className="authorname">Gaurav Singh | <span className="degignt">Creative Professional</span></h4>
                        <div className="star-rating mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.52901 14.4083L3.96375 17.5235L5.20652 11.2678L0.523438 6.93744L6.85724 6.18625L9.52901 0.394531L12.2008 6.18625L18.5346 6.93744L13.8515 11.2678L15.0943 17.5235L9.52901 14.4083Z" fill="#B4B4B4"/>
                            </svg>
                        </div>                
                        <p>“Yoga and meditation are a part of my daily routine but I was a bit undisciplined in my practice. I happened upon the ThinkRight.Me app and it gave me the apt motivation to change my ways.” - Gaurav</p>
                    </div>
                </div>
                <div>
                    <div className="testimonial-cart">
                        <a href="#" onClick={(e)=>playTestimonial(e,"SVw_21RDZCc")} data-id="SVw_21RDZCc" data-bs-toggle="modal" data-bs-target="#videoPopup">
                            <div className="cart-img">
                                <img src={ashishJoshi} />
                                <div className="play-btn">
                                    <img src={playIcon} />
                                </div>
                            </div>
                        </a>
                        <h4 className="authorname">Ashish Joshi | <span className="degignt">Engineer</span></h4>
                        <div className="star-rating mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="19px" height="18px" viewBox="0 0 32 32">
                            <defs>
                            <linearGradient id="grad">
                            <stop offset="50%" stop-color="#FFA500"/>
                            <stop offset="50%" stop-color="grey"/>
                            </linearGradient>
                            </defs>
                            <path fill="url(#grad)" d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118
                            l11.547-1.2L16.026,0.6L20.388,10.918z"/>
                            </svg>
                        </div>                
                        <p>“I came across ThinkRight.Me online and began following Sister Shivani. I follow the Daily Journeys by her regularly and they help me understand and deal with my emotions better.” - Ashish</p>
                    </div>
                </div>
                <div>
                    <div className="testimonial-cart">
                        <a href="#" onClick={(e)=>playTestimonial(e,"JOMHNQJTGjU")} data-id="JOMHNQJTGjU" data-bs-toggle="modal" data-bs-target="#videoPopup">
                            <div className="cart-img">
                                <img src={neelima} />
                                <div className="play-btn">
                                    <img src={playIcon} />
                                </div>
                            </div>
                        </a>
                        <h4 className="authorname">Neelima Mehra | <span className="degignt">Home Chef</span></h4>
                        <div className="star-rating mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                                </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                                </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                                </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                                </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg>
                        </div>                
                        <p>“Being a home chef and a mother of two, I always felt like I wasn’t doing enough for everyone. ThinkRight.Me helped me self-soothe and rewire my mindset into a positive one.” - Neelima</p>
                    </div>
                </div>
                <div>
                    <div className="testimonial-cart">
                        <a href="#" onClick={(e)=>playTestimonial(e,"RKrw12dYOP0")} data-id="RKrw12dYOP0" data-bs-toggle="modal" data-bs-target="#videoPopup">
                            <div className="cart-img">
                                <img src={raksha} />
                                <div className="play-btn">
                                    <img src={playIcon} />
                                </div>
                            </div>
                        </a>
                        <h4 className="authorname">Raksha Kumawat | <span className="degignt">Theatre Actor</span></h4>
                        <div className="star-rating mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.52901 14.4083L3.96375 17.5235L5.20652 11.2678L0.523438 6.93744L6.85724 6.18625L9.52901 0.394531L12.2008 6.18625L18.5346 6.93744L13.8515 11.2678L15.0943 17.5235L9.52901 14.4083Z" fill="#B4B4B4"/>
                            </svg>
                        </div>                
                        <p>“When I started affirming everything through the ThinkRight.Me app, I saw a visible change in myself. Physically and mentally, I became calmer, my days were organized, and I knew where I was heading. It’s been a massive help.” - Raksha</p>
                    </div>
                </div>
                <div>
                    <div className="testimonial-cart">
                        <a href="#" onClick={(e)=>playTestimonial(e,"x1-VWwIbyYo")} data-id="x1-VWwIbyYo" data-bs-toggle="modal" data-bs-target="#videoPopup">
                            <div className="cart-img">
                                <img src={heena} />
                                <div className="play-btn">
                                    <img src={playIcon} />
                                </div>
                            </div>
                        </a>
                        <h4 className="authorname">Heena Lulla | <span className="degignt">Working Mother</span></h4>
                        <div className="star-rating mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.46651 14.4083L3.90125 17.5235L5.14402 11.2678L0.460938 6.93744L6.79474 6.18625L9.46651 0.394531L12.1383 6.18625L18.4721 6.93744L13.789 11.2678L15.0318 17.5235L9.46651 14.4083Z" fill="#FFA500"/>
                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                            <path d="M9.52901 14.4083L3.96375 17.5235L5.20652 11.2678L0.523438 6.93744L6.85724 6.18625L9.52901 0.394531L12.2008 6.18625L18.5346 6.93744L13.8515 11.2678L15.0943 17.5235L9.52901 14.4083Z" fill="#B4B4B4"/>
                            </svg>
                        </div>                
                        <p>“I used to get very restless suffering separation anxiety with my child. My colleague suggested using ThinkRight.Me app and I found a supportive and emotional companion in the app. Even my daughter loves listening to the Daily Journey with me.” - Heena</p>
                    </div>
                </div>
                {/* <div>
                    <div className="testimonial-cart">
                        <a href="#" onClick={(e)=>playTestimonial(e,"NT31LOCFlqk")} data-id="NT31LOCFlqk" data-bs-toggle="modal" data-bs-target="#videoPopup">
                            <div className="cart-img">
                                <img src="./assets/images/testimonial-1.png" />
                                <div className="play-btn">
                                    <img src="./assets/images/play-icon.svg" />
                                </div>
                            </div>
                        </a>
                        <h4 className="authorname">Birindar | <span className="degignt">IT Professional</span></h4>
                        <div className="star-rating mb-3">
                            <img src="./assets/images/star-rating-img.svg" />
                        </div>                
                        <p>“My wife meditates to destress and she recommended the ThinkRight.Me app to me. As a novice, I found the meditations simple, useful, and relatable, it’s become my go-to for a variety of situations. I favourite them to revisit during tough times.” – Birindar</p>
                    </div>
                </div> */}
                {/* <div>
                    <div className="testimonial-cart">
                        <a href="#" onClick={(e)=>playTestimonial(e,"6pLCxkgYup4")} data-id="6pLCxkgYup4" data-bs-toggle="modal" data-bs-target="#videoPopup">
                            <div className="cart-img">
                                <img src="./assets/images/testimonial-2.png" />
                                <div className="play-btn">
                                    <img src="./assets/images/play-icon.svg" />
                                </div>
                            </div>
                        </a>
                        <h4 className="authorname">Birindar | <span className="degignt">IT Professional</span></h4>
                        <div className="star-rating mb-3">
                            <img src="./assets/images/star-rating-img.svg" />
                        </div>                
                        <p>“My wife meditates to destress and she recommended the ThinkRight.Me app to me. As a novice, I found the meditations simple, useful, and relatable, it’s become my go-to for a variety of situations. I favourite them to revisit during tough times.” – Birindar</p>
                    </div>
                </div> */}
            </Slider>
        </div>
        <TestimonialVideoModal src={src} setSrc={setSrc} iframeModal={iframeModal} setIframeModal={setIframeModal}/>
    </>
  )
}
