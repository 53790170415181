import * as constants from "../Constants";

export async function favouriteUnFavouriteMark(data){
    const response = await fetch(constants.API_URL+`v3/favourites/favouriteUnFavouriteMark_1_1` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}


export async function getFavouriteContentList(data){
    const response = await fetch(constants.API_URL+`v3/favourites/getFavouriteContentList_1_1` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}