//import constants
import * as constants from "../Constants";

export async function getRenewalUserOfferDetails(data){

	const response = await fetch(constants.API_URL+`v3/renewalOffer/getuserOfferDetails_1_0` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}