//call base url
export const BASE_URL = "https://user.thinkrightme.com/";

//api call base url
export const API_URL = "https://thinkrightme.publicam.in/";

//set superstoreid
export const SUPER_STORE_ID = 173;

// export const SUPER_STORE_ID = 181;

export const VERSION 		= localStorage.getItem("version");

//set api package name
export const PACKAGE_NAME = "in.publicam.thinkrightme";

export const TEST_USERCODE = "173-1672136-1614769717";

//google recaptcha site key
export const SITE_KEY = "6Le3Np0lAAAAAOlZjyiTeJpsQoxGDvwqaI8GNgWz";
