import React from 'react'

//import constants
import * as constants from "../../../../Constants";
import Subscription from '../../subscription/Subscription';
import Success from '../../subscription/Success'; 
import PurchaseSuccess from '../gift-voucher/PurchaseSuccess';
import Failed from '../../subscription/Failed';
// import View_all_offer  from '../../subscription/View_all_offer';
import {getUserSubscriptionStatus} from '../../../../services/InApp';
import { Link } from 'react-router-dom';
import { getRenewalUserOfferDetails } from '../../../../services/RenewalOffer';

//import gift voucher component
import GiftVoucherDetails from '../gift-voucher/GiftVoucherDetails';


export default class ManageSubscription extends React.Component {
    
    constructor(props){
        super(props);
        let remainingDays = 0;
        this.state = {
            responseSubscriptionDetails : {},
            isUserSubscribe: 0,
            isRenewClick: 0 ,
            isNearRenewalUser: 0,
            customCouponCode:'',
            openVoucherPop: false
        };
    }
    
    componentDidMount() {
        // console.log(this.props.helpUrls);
        let getUserSubscriptionPayload = {
            superStoreId    : constants.SUPER_STORE_ID,
            userCode        : localStorage.getItem('userCode'),
            locale          : constants.LOCALE
        }

        let modelName = '';
        getUserSubscriptionStatus(getUserSubscriptionPayload).then(response =>{
            let isSubscribe = false;
            if(response.code === 200){
                isSubscribe = true;
                this.setState({isUserSubscribe:1});
                this.setState({responseSubscriptionDetails:response.data});
            };

            if(this.props.payment_success === true){
                modelName = 'success';
            }else if (this.props.payment_success === false && this.props.voucherPaymentSuccess !== true){
                modelName = 'failed';
            }else if(isSubscribe === false) {
                modelName = 'subscription';
            }else if(this.props.voucherPaymentSuccess === true){
                modelName = 'voucherSuccess';
            }else if(this.props.voucherPurchaseSource === "home"){
                modelName = 'voucherPurchase';
            }

            if(modelName !== ''){
                window.addEventListener('load', this.handleLoad(modelName));
            }
            
            //START Get user renewal offer details
            let userPayload = {
                userCode: localStorage.getItem('userCode'),
                superStoreId: constants.SUPER_STORE_ID,
                locale      : constants.LOCALE
            }
            getRenewalUserOfferDetails(userPayload)
            .then(response => {
                // console.log("Renewal user offer details",response);
                if(response.code === 200){
                    this.remainingDays = response.data.daysRemaining;
                    this.setState({isNearRenewalUser:1});
                    window.addEventListener('load', this.handleLoad("subscription"));
                }

            })
            //END Get user renewal offer details
        });  
    }

    //below code get executed after page load
    handleLoad(modelName) {
        if(modelName === 'success'){
            window.$("#payment-success").modal("show");
        }else if( modelName === 'failed' ){
            window.$("#payment-failed").modal("show");
        }else if(modelName === 'subscription' && 
            (this.props.subscriptionSource === "login" ||
            this.props.subscriptionSource === "paidContent" ||
            this.props.subscriptionSource === "homeOfferBanner" ||
            this.props.subscriptionSource === "homeFavourite" ||
            this.props.subscriptionSource === "dailyAffirmOfferBanner" ||
            this.props.subscriptionSource === "dailyAffirmFavourite" ||
            this.props.subscriptionSource === "morningZenBanner" ||
            this.props.subscriptionSource === "sound"||
            this.props.subscriptionSource === "meditationPage"||
            this.props.subscriptionSource === "yogaPage"||
            this.props.subscriptionSource === "search"
            )
            ){
            window.$("#subscriptionModal").modal("show");
        }else if(modelName === 'subscription' && this.props.showOfferPopUp === true){
            window.$("#subscriptionModal").modal("show");
        }else if(modelName === 'voucherSuccess'){
            window.$("#voucher-payment-success").modal("show");
        }else if(modelName === "voucherPurchase"){
            this.setState({openVoucherPop:true})
            // this.renderGiftVoucherDetail();
            // window.$("#voucher-payment-success").modal("show");
        }
    }

    subscriptionToggle = (e) => {
        // console.log("e--->",e.target.dataset.type);
        if(e.target.dataset.type && e.target.dataset.type === "renew"){
            // console.log("here");
            this.setState({isRenewClick:1});
        }
        
        e.preventDefault();
        window.$("#subscriptionModal").modal("show");
    };

    renderSubscription() {
        return (<Subscription showOfferPopUp={this.props.showOfferPopUp} handleShowOfferPopUp={this.props.handleShowOfferPopUp} subscriptionSource={this.props.subscriptionSource} setsubscriptionSource={this.props.setsubscriptionSource} helpUrls={this.props.helpUrls} isRenewClick={this.state.isRenewClick} setisRenewClick={this.props.setisRenewClick} customCouponCode={this.props.customCouponCode} />);
    };
    renderPaymentSuccess() {
        return (<Success helpUrls={this.props.helpUrls}/>);
    };
    renderVoucherPaymentSuccess() {
        return (<PurchaseSuccess/>);
    };
    renderPaymentFailed() {
       // console.log("In payment failed");
        return (<Failed/>);
    };

    
    handleOpenVoucherPop(){

    }

    handleOpenVoucherPop = openVoucherPop =>{
        this.setState({openVoucherPop})
      }

    handleVoucherPurchaseSource = voucherPurchaseSource =>{
        this.setState({voucherPurchaseSource}) 
    }

    renderGiftVoucherDetail(){
        console.log("In render gift voucher");
        // return (<GiftVoucherDetails helpUrls={this.props.helpUrls} openVoucherPop={this.state.openVoucherPop} handleOpenVoucherPop={this.handleOpenVoucherPop} handleVoucherPurchaseSource={this.handleVoucherPurchaseSource}/>);
        return (<GiftVoucherDetails helpUrls={this.props.helpUrls} openVoucherPop={this.state.openVoucherPop} handleOpenVoucherPop={this.handleOpenVoucherPop} setvoucherPurchaseSource={this.props.setvoucherPurchaseSource}/>);
        
    }

    render() {
        const isUserSubscribe   = this.state.isUserSubscribe;
        const isNearRenewalUser = this.state.isNearRenewalUser;

        // console.log("isNearRenewalUser",isNearRenewalUser);
        
        // console.log("User Details",this.state.responseSubscriptionDetails);
        const userSubscriptionDetails = this.state.responseSubscriptionDetails[0];

        // console.log("userSubscriptionDetails",userSubscriptionDetails);

        return (
            <>
            <div className="row">
            {isUserSubscribe === 1  && isNearRenewalUser === 0 ? (
                <div className="col-6 align-items-end">
                    <div className="subscription_card d-flex align-items-center">
                        <div className="card-left-text align-self-center w-70">
                            <h6>{userSubscriptionDetails.plan_duration} {userSubscriptionDetails.plan_duration_type} Plan </h6>
                            <h6>Valid From {userSubscriptionDetails.valid_from}  </h6>
                            <h6>Valid Till {userSubscriptionDetails.valid_till}  </h6>
                            {/* <p>Show you care by gifting an annual subscription to your loved ones.</p> */}
                        </div>
                        <div className="day-left align-self-center text-center">
                            <img width="90" src="../dashboard-assets/images/profile-img.png" alt='profile-img' className="img-fluid d-block mx-auto pe-3"/>
                        </div>
                    </div>
                </div>
            ) : ""
            }

            {isUserSubscribe === 0  && isNearRenewalUser === 0 ? (
                <div className="col-6 mb-5">
                    <div className="spread-joy">
                        <div className="d-flex">
                            <div className="card-right-text align-self-center">
                                <h4>Begin your journey</h4>
                                <p>Unlock more meditations, courses, music, meditation tools & much more.</p>
                                <div className="sb_btn">
                            <Link id="subscription" to="" onClick={(e) => this.subscriptionToggle(e)} className="d-block" data-bs-toggle="modal" data-bs-target="#exampleModal">Subscribe Now </Link>
                        </div>
                            </div>
                            <div className="card-right-img">
                                <div className="position-relative">
                                    <img src="../dashboard-assets/images/gift-card.png" alt='gift-card' className="img-fluid"/>
                                    <img src="../dashboard-assets/images/med-img.png" alt='med-img' className="med-img-abs img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                ) : ""
            }                
                
            {/* use below code base for near renewal user*/}
            {
                isUserSubscribe === 1  && isNearRenewalUser === 1 ? (
                    <div class="col-5 align-items-end mb-5">
                    <div class="subscription_card">
                        <div class="d-flex">
                            <div class="card-left-text">
                            <h6>{userSubscriptionDetails.plan_duration} {userSubscriptionDetails.plan_duration_type} Plan </h6>
                            <h6>Valid From {userSubscriptionDetails.valid_from}  </h6>
                            <h6>Valid Till {userSubscriptionDetails.valid_till}  </h6>
                                {/* <p>Subscribe now and get 1 month extra </p> */}
                            </div>
                            <div class="day-left align-self-center text-center">
                                <span>{this.remainingDays} days Left</span>
                            </div>
                        </div>
                        <div class="sb_btn">
                            {/* <a href="#" class="d-block" data-bs-toggle="modal" data-bs-target="#exampleModal">Renew Now </a> */}
                            <Link id="subscription" to="" onClick={(e) => this.subscriptionToggle(e)} className="d-block" data-bs-toggle="modal" data-bs-target="#exampleModal" data-type="renew">Renew Now</Link>
                        </div>
                    </div>
                </div>
                ) : ""
            }
            {this.renderGiftVoucherDetail()}
            </div>

            {this.renderSubscription()}
            
            {this.renderPaymentSuccess()}
            {this.renderVoucherPaymentSuccess()}
            {this.renderPaymentFailed()}
            
            </>
        );
    }
}
