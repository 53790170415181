import React,{useEffect,useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import * as constants from "../../../../Constants";
import RecommendedContentCard from './RecommendedContentCard';
import { getPortletDetails } from '../../../../services/Portlet';

//Component Description - Content completion scrren after completing meditation content
export default function ContentCompletionModal({content,contentData, detailModal,handleContentDetail,timerModal,handleTimerModal,videoModal,handleVideoModal,completionModal,handleCompletionModal,handleSocialShare,subscriptionSource,setsubscriptionSource,handleIsLibraryjourney,isUserSubscribe,isContentUnlockFreePassUser}) {
  const [recommendedContent,setRecommendedContent]=useState([]);
  const [refreshData,setRefreshData]=useState(0);
  let contentId = content.id;

    let poster_url = "";
    if(content.content_type==="news" && content.entities.news_articles[0].media_url!==null){
        poster_url = content.entities.news_articles[0].media_thumb_url;
    }else if(content.content_type==="audio"){
        poster_url = content.entities.audio.thumb_image[0]
    }else{
        poster_url = content.entities.image.thumb_image[0];
    }
    let page = content.page;
    useEffect(() => {
      
      let payload ={
          userCode: localStorage.getItem('userCode'),
					superStoreId : constants.SUPER_STORE_ID,
					storeId: content.store_id,
          pageId: content.page_id,
					portletId: content.portlet_id,
					page:"1",
					locale : constants.LOCALE
      }
      getPortletDetails(payload).then(response=>{
        if(response.code===200){
          if(recommendedContent.length===0){
            setRecommendedContent(response.data.content_data.sort(() => Math.random() - 0.5));
          }
          if(refreshData===0){
            setRefreshData(1)
          }
        }else{
          setRecommendedContent([]);
        }
      })
      
    },[content,refreshData])
    
    const shareToggle = (e) =>{
      e.preventDefault();
      handleSocialShare(true);
    }

    const closeModal = (e) =>{
      e.preventDefault();
      handleIsLibraryjourney(true);
      handleCompletionModal(false);
    }

  return (
    <Modal
    show={completionModal}
    onHide={(e) => closeModal(e)}
    id="recommend" 
    dialogClassName=" modal-fullscreen modal-dialog-scrollable"
    contentClassName="height-100pc"
    tabIndex="-1" 
    aria-labelledby="recommendlabel" 
    aria-hidden="true"
    >
       <button type="button" className="btn-close video-close" data-bs-dismiss="modal" onClick={(e) => closeModal(e)} aria-label="Close"></button>
      <div className="modal-body p-0">
        <video width="100%" height="100%" poster={poster_url}>
          <source src="" type="video/mp4"/>
        </video>
        <div className="player-main-screen video-end-txt trending-content-section">
          <div className="text-center text-wraper-end">
            <h3 className='txt-shadow'>Congratulations!</h3>
            <p className="success-txt txt-shadow">You’ve sucessfully completed “{content.content_title}”  </p>
            <Link to="" onClick={(e)=>shareToggle(e)} className="share-btn-end">Share</Link>
          </div>
          <div className="recommd-heading">
            <p>Recommendations for you </p>
          </div>
          <div className="d-flex flex-wrap justify-content-start row" style={{padding:"0 150px"}}>
            {
              recommendedContent.length!==0 ?
              recommendedContent
              .filter((content) => content.id !== contentId)
              .filter((content,i) => i < 5)
              .map((content,i)=>(
                  <RecommendedContentCard content={content} key={i} contentData={contentData} handleCompletionModal={handleCompletionModal} handleContentDetail={handleContentDetail} handleVideoModal={handleVideoModal} handleIsLibraryjourney={handleIsLibraryjourney} isUserSubscribe={isUserSubscribe} subscriptionSource={subscriptionSource} setsubscriptionSource={setsubscriptionSource} page={page} isContentUnlockFreePassUser={isContentUnlockFreePassUser} setRecommendedContent={setRecommendedContent}/>
              ))
              :""
            }
          </div>   
        </div>
      </div>
</Modal>
  )
}
