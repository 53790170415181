import React from 'react';
// import View_all_offer from './View_all_offer';
import * as constants from "../../../Constants";

import {getSubscriptionPlans, initiateSubscription, applyPromoCodeSubscription, userSubscribeByCoupan} from '../../../services/InApp';
import { getAppConfig } from '../../../services/AppConfig';
import {getOffersData} from '../../../services/SubscriptionOffers';
import {  Navigate,Link  } from 'react-router-dom';
import { getUserBase } from '../../../services/User';
import { getRenewalUserOfferDetails } from '../../../services/RenewalOffer';

export default class Subscription extends React.Component {

	constructor(props){
        super(props);

        this.state = {
			subscriptionBenefits: [],
			planPrice: 0,
			planDuration: this.props.planDuration,
			planDurationType:'',
			ActualPlanPrice: 0,
			ActualPlanDuration: 0,
			ActualPlanDurationType:'',
			ActualEventId:'',
			subscriptionOffers: [],
			subscriptionPackageId:0,
			offerType:'',
			customCouponCode:'',
			errorMessage:'',
			redirectHome: false,
			redirectAffirmation: false,
			redirectMorningZen: false,
			redirectSound: false,
			redirectMeditation: false,
			redirectYoga: false,
			redirectSearch: false,
			terminateSubFlow:false,
			appliedOfferCode:this.props.appliedOfferCode,
			isNearRenewalUser: 0,
			jetPayPayload:{
				appId:0,
				operator: "",
				partnerId:0,
				countryName:"",
				cpEvent: "",
				transId :"",
				cmode : "",
				subAccountId : 0,
				unitType :"",
				returnUrl :"",
				failReturnUrl:"",
				currency:"",
				amount:0
			}
        };

		this.handleSubmit = this.handleSubmit.bind(this);
		this.data = [];
    }

    componentDidMount() {

		// console.log("this.props.isNearRenewalUser",this.props.isNearRenewalUser);

		// console.log(this.props.helpUrls);
		this.setState({appliedOfferCode:""});

		let configPayload = {
			package_name: constants.PACKAGE_NAME,
			platformType: constants.LOCALE.platform,
			locale      : constants.LOCALE
		}

		getAppConfig(configPayload)
		.then( responseConfig => {
			
			//set subscriptionPackageId in state
			this.setState({subscriptionPackageId:responseConfig.data.app_subscription_pkg_id});

			let requestPayloadGetPlans = {
				superStoreId    : constants.SUPER_STORE_ID,
				parentPackageId : responseConfig.data.app_subscription_pkg_id,
				packageId 		: responseConfig.data.app_subscription_pkg_id,
				locale          : constants.LOCALE
			};
	
			getSubscriptionPlans(requestPayloadGetPlans)
			.then(response => {
			
				this.setState.subscriptionBenefits = response.data.packageDescription.split("|");
				let benefits = response.data.packageDescription.split("|");
				benefits.shift();
				this.setState({subscriptionBenefits: benefits});

				this.setState({planPrice: response.data.planDetails[0]['channelList'][0]['pricePointValue'][0]['value']});
				this.setState({planDuration: response.data.planDetails[0]['planDuration']});
				this.setState({planDurationType: response.data.planDetails[0]['planDurType']});

				this.setState({ActualPlanPrice: response.data.planDetails[0]['channelList'][0]['pricePointValue'][0]['value']});
				this.setState({ActualPlanDuration: response.data.planDetails[0]['planDuration']});
				this.setState({ActualPlanDurationType: response.data.planDetails[0]['planDurType']});
				this.setState({ActualEventId: response.data.planDetails[0]['channelList'][0]['channelEventId']});

				//START Get user renewal offer details
				getRenewalUserOfferDetails(userPayload)
				.then(responseRenewalOffer => {
					// console.log("Renewal user offer details",responseRenewalOffer);
					if(responseRenewalOffer.code === 200){
						this.setState({appliedOfferCode:responseRenewalOffer.data.offerCode});
						this.setState({isNearRenewalUser:1});
						let applyPromoCodePayload = {
							userCode: localStorage.getItem('userCode'),
							superStoreId: constants.SUPER_STORE_ID,
							parentPackageId : responseConfig.data.app_subscription_pkg_id,
							packageId: responseConfig.data.app_subscription_pkg_id,
							coupanCode:responseRenewalOffer.data.offerCode,
							// coupanCode:"RENEW15MONTHS50",
							locale    : constants.LOCALE
						}
						this.subscriptionWithOfferCode(applyPromoCodePayload);
					}else{

						//prepare initiate subscription payload to get txid other details for jetpay payload
						let initateSubscriptionPayload = {};
						initateSubscriptionPayload.superStoreId = constants.SUPER_STORE_ID;
						initateSubscriptionPayload.userCode = localStorage.getItem('userCode');
						initateSubscriptionPayload.storeId 	= response.data.storeId;
						initateSubscriptionPayload.parentPackageId 	= responseConfig.data.app_subscription_pkg_id;
						initateSubscriptionPayload.packageId 	= responseConfig.data.app_subscription_pkg_id;
						initateSubscriptionPayload.planId 		= response.data.planDetails[0].planId;
						initateSubscriptionPayload.channelId 	= response.data.planDetails[0]['channelList'][0]['channelId'];
						initateSubscriptionPayload.eventId 		= response.data.planDetails[0]['channelList'][0]['channelEventId'];
						initateSubscriptionPayload.amount 		= response.data.planDetails[0]['channelList'][0]['pricePointValue'][0]['value'];
						initateSubscriptionPayload.cmode 		= "APP";
						initateSubscriptionPayload.unitType 	= response.data.unitType;
						initateSubscriptionPayload.channelTag 	= response.data.planDetails[0]['channelList'][0]['channelTag'];
						initateSubscriptionPayload.currency 	= response.data.planDetails[0]['channelList'][0]['pricePointValue'][0]['currency'];
						initateSubscriptionPayload.locale       = constants.LOCALE;
			
						this.initSubscription(initateSubscriptionPayload);
					}
				})
				//END Get user renewal offer details			
			});
		});
		//getUserbase for userbased offers
		let userPayload = {
			userCode: localStorage.getItem('userCode'),
			superStoreId: constants.SUPER_STORE_ID,
			locale      : constants.LOCALE
		}
		
		getUserBase(userPayload)
		.then(response => {
			//set userbase in localstorage
			localStorage.setItem('userBase',response.data.userBase);

			//START get subscription offer list
			let subscriptionOfferPayload = {
				userCode: localStorage.getItem('userCode'),
				userBased :response.data.userBase,
				superStoreId: constants.SUPER_STORE_ID,
				locale      : constants.LOCALE
			}

			getOffersData(subscriptionOfferPayload)
				.then( responseConfig => {
				this.data = responseConfig.data;
			});
			//END get subscription offer list
		})
    }

	componentDidUpdate(prevProps, prevState, snapshot){
		if(this.props.showOfferPopUp === true){
			setTimeout(() => {
				this.ViewOfferToggle();
			}, "500");
		}
	}

	SuccessToggle = (e) => {
		window.$("#fullsecreenModal-3").modal("show");
	};
	
	ViewOfferToggle = (e) => {
		this.props.handleShowOfferPopUp(false);
		// console.log(e);
		// console.log("ViewOfferToggle");

		// e.preventDefault();
		if(e !== undefined){
			this.setState({
				errorMessage:"",
				customCouponCode:""
			});
		}
		window.$("#coupon_list").modal("show");
	};

	handleSubmit(event) {
		
		if( this.state.terminateSubFlow === true ){
			event.preventDefault();
			let applyPromoCodePayload = {
				userCode: localStorage.getItem('userCode'),
				superStoreId: constants.SUPER_STORE_ID,
				parentPackageId : this.state.subscriptionPackageId,
				packageId: this.state.subscriptionPackageId,
				locale    : constants.LOCALE
			}
			applyPromoCodePayload.coupanCode= this.state.appliedOfferCode;
			this.subscribeWithVoucherCode(applyPromoCodePayload);
		}else{
			document.getElementById("subscription").submit();
		}
	}

	applyVoucherCode(event){

		// console.log(event.target.attributes.getNamedItem("data-val").value);

		event.preventDefault();
		let appliedOfferCode = event.target.attributes.getNamedItem("data-val").value;
		this.setState({appliedOfferCode:event.target.attributes.getNamedItem("data-val").value});

		let applyPromoCodePayload = {
			userCode: localStorage.getItem('userCode'),
			superStoreId: constants.SUPER_STORE_ID,
			parentPackageId : this.state.subscriptionPackageId,
			packageId: this.state.subscriptionPackageId,
			locale    : constants.LOCALE
		}

		//if the applied offer code is matched with given available offer then continue with subscription
		//else check free offer code

		// console.log("appliedOfferCode-->",appliedOfferCode);

		if( appliedOfferCode !== "CUSTOM" ){
			applyPromoCodePayload.coupanCode= appliedOfferCode;
			this.subscriptionWithOfferCode(applyPromoCodePayload);
		}else{

			// console.log("addf",this.state.customCouponCode);

			if( this.data.some(item => item.offer_code.toLowerCase() === this.state.customCouponCode.toLowerCase()) ){
				applyPromoCodePayload.coupanCode= this.state.customCouponCode;
				this.setState({appliedOfferCode:this.state.customCouponCode});
				this.subscriptionWithOfferCode(applyPromoCodePayload);
			}else{
				applyPromoCodePayload.coupanCode= this.state.customCouponCode;
				this.subscribeWithVoucherCode(applyPromoCodePayload);
			}
		}
	}

	//START subscription using voucher code & Free offer code
	subscribeWithVoucherCode(payload){
		userSubscribeByCoupan(payload)
			.then( response => {
				// console.log(response)
				if(response.code === 200){
					window.$("#coupon_list").modal("hide");
					window.$("#payment-success").modal("show");
				}else{
					this.setState({
						errorMessage:response.message
					});
				}
		});
	}
	//END subscription using voucher code & Free offer code

	//START subscription using offer code & its paid subscription
	subscriptionWithOfferCode(payload){
		
		applyPromoCodeSubscription(payload)
			.then( response => {
				// this.setState({isRenewClick: 0});
				if(response.code === 200){
					
					//If terminate subscription flow is true then give free subscription
					if( response.terminateSubFlow === true ){
						this.setState({terminateSubFlow:true});	
					}

					var cpEvent = response.data.planDetails[0].channelList[0]['channelEventId'];
				
					var jetPayPayloadTemp = this.state.jetPayPayload;
					var jetPayPayload = {...this.state.jetPayPayload};

					// console.log("jetPayPayloadTemp",jetPayPayloadTemp);
					// console.log("jetPayPayload",jetPayPayload);


					jetPayPayload.appId 	=  jetPayPayload.appId;
					jetPayPayload.cpEvent 	=  cpEvent;
					jetPayPayload.unitType 	=  response.data.unitType;
					
					// this.setState({jetPayPayload});

					this.setState({offerType:response.data.offerType});
					this.setState({planPrice:response.data.planDetails[0]['channelList'][0]['pricePointValue'][0]['value']});
					this.setState({planDuration:response.data.planDetails[0]['planDuration']});
					this.setState({planDurationType:response.data.planDetails[0]['planDurType']})
					window.$("#coupon_list").modal("hide");

					// console.log("apply promo response",response);

					//After voucher code applied intite subscription again
					let initateSubscriptionPayload = {};
					initateSubscriptionPayload.superStoreId = constants.SUPER_STORE_ID;
					initateSubscriptionPayload.userCode = localStorage.getItem('userCode');
					initateSubscriptionPayload.storeId 	= response.data.storeId;
					initateSubscriptionPayload.parentPackageId 	= response.data.packageId;
					initateSubscriptionPayload.packageId 		= response.data.packageId;
					initateSubscriptionPayload.planId 		= response.data.planDetails[0].planId;
					initateSubscriptionPayload.channelId 	= response.data.planDetails[0]['channelList'][0]['channelId'];
					initateSubscriptionPayload.eventId 		= response.data.planDetails[0]['channelList'][0]['channelEventId'];
					initateSubscriptionPayload.amount 		= response.data.planDetails[0]['channelList'][0]['pricePointValue'][0]['value'];
					initateSubscriptionPayload.cmode 		= "APP";
					initateSubscriptionPayload.unitType 	= response.data.unitType;
					initateSubscriptionPayload.channelTag 	= response.data.planDetails[0]['channelList'][0]['channelTag'];
					initateSubscriptionPayload.currency 	= response.data.planDetails[0]['channelList'][0]['pricePointValue'][0]['currency'];
					initateSubscriptionPayload.promoCode 	= payload.coupanCode;
					initateSubscriptionPayload.locale       = constants.LOCALE;

					// console.log("initateSubscriptionPayload",initateSubscriptionPayload);

					this.initSubscription(initateSubscriptionPayload);


				}else{
					this.setState({
						errorMessage:response.message
					})
				}
		});
	}
	//END subscription using offer code & its paid subscription

	//START init subscription
	initSubscription(initateSubscriptionPayload){
		//prepare initiate subscription payload to get txid other details for jetpay payload

		initiateSubscription(initateSubscriptionPayload).then(response => {

			if( response.code === 200 && response.data !== undefined ){
				var payLoad = response.data.payload;
		
				var jetPayPayload = {...this.state.jetPayPayload}
				jetPayPayload.appId 	= response.data.payload.appId;
				jetPayPayload.operator 	= payLoad.operator;
				jetPayPayload.partnerId = payLoad.partnerId;
				jetPayPayload.countryName = payLoad.countryName;
				jetPayPayload.cpEvent 	=  payLoad.cpEvent;
				jetPayPayload.transId 	= payLoad.transId;
				jetPayPayload.cmode 	= payLoad.cmode;
				jetPayPayload.subAccountId = payLoad.subAccountId;
				jetPayPayload.unitType 	= payLoad.unitType;
				jetPayPayload.returnUrl = payLoad.returnUrl;
				jetPayPayload.failReturnUrl = payLoad.failReturnUrl;
				jetPayPayload.currency 	= payLoad.currency;
				jetPayPayload.amount 	= payLoad.amount;
				jetPayPayload.transaction_url = response.data.transaction_url;
				this.setState({jetPayPayload});
			}
		});
	}
	//END init subscription

	//START GET custom coupon code value 
	updateInputValue(event) {
		// console.log("update value");
		const val = event.target.value; 

		// console.log("val",val);

		this.setState({
			customCouponCode: val
		});
	}
	//END GET custom coupon code value 

	//START remove promo code
	removePromoCode(event){
		event.preventDefault();
		this.componentDidMount();
		// this.setState({appliedOfferCode:""});
		// this.setState({offerType:""});
		// this.setState({planPrice:this.state.ActualPlanPrice});
		// this.setState({planDuration:this.state.ActualPlanDuration});
		// this.setState({planDurationType:this.state.ActualPlanDurationType});

		// var jetPayPayload = {...this.state.jetPayPayload}
		// jetPayPayload.cpEvent 	=  this.state.ActualEventId;
		// this.setState({jetPayPayload});
	}
	//END remove promo code

	//START On close model
	closeModel(event){
		if(this.props.subscriptionSource === "login" || this.props.subscriptionSource === "paidContent"){
			this.setState({ redirectHome: true });
		}else if( this.props.subscriptionSource === "homeOfferBanner" || this.props.subscriptionSource === "homeFavourite" ){
			this.setState({ redirectHome: true });
		}else if( this.props.subscriptionSource === "dailyAffirmOfferBanner" || this.props.subscriptionSource === "dailyAffirmFavourite" ){
			this.setState({ redirectAffirmation: true });
		}else if( this.props.subscriptionSource === "morningZenBanner" ){
			this.setState({ redirectMorningZen: true });
		}else if( this.props.subscriptionSource === "sound" ){
			this.setState({ redirectSound: true });
		}else if( this.props.subscriptionSource === "meditationPage" ){
			this.setState({ redirectMeditation: true });
		}else if( this.props.subscriptionSource === "yogaPage" ){
			this.setState({ redirectYoga: true });
		}else if( this.props.subscriptionSource === "search" ){
			this.setState({ redirectSearch: true });
		}
		this.props.setsubscriptionSource("");
		this.componentDidMount();
	}

	closeOfferModel(event){

		// console.log("in handleShowOfferPopUp");
		event.preventDefault();
		this.props.handleShowOfferPopUp(false);
	}
	//END On close model
	TermConToggle(e){
      e.preventDefault();
      window.$("#tnc").modal("show");
    };

	PrivcyPolcToggle = (e) => {
		e.preventDefault();
		window.$("#privacypop").modal("show");
	};

	render() {
		const { redirectHome } = this.state;
		const { redirectAffirmation } = this.state;
		const { redirectMorningZen } = this.state;
		const { redirectSound } = this.state;
		const { redirectMeditation } = this.state;
		const { redirectYoga } = this.state;
		const { redirectSearch } = this.state;

		if (redirectHome) {
			return <Navigate to='/dashboard/home'/>;
		}
		if(redirectAffirmation){
			return <Navigate to='/dashboard/dailyAffirmation'/>;
		}
		if(redirectMorningZen){
			return <Navigate to='/dashboard/morningzen'/>;
		}
		if(redirectSound){
			return <Navigate to='/dashboard/sound'/>;
		}
		if(redirectMeditation){
			return <Navigate to='/dashboard/meditation'/>;
		}
		if(redirectYoga){
			return <Navigate to='/dashboard/yoga'/>;
		}
		if(redirectSearch){
			return <Navigate to='/dashboard/search'/>;
		}

		let appid = this.state.appId;
		let offers = this.data;
		
		let appliedOfferCode = "";

		if(this.state.appliedOfferCode !=="CUSTOM"){
			appliedOfferCode = this.state.appliedOfferCode;
		}	

		let isNearRenewalUser = this.state.isNearRenewalUser;

		return (
			<>
			{/* Modal */}
			<div className="modal fade subscription-modal" id="subscriptionModal" tabIndex="-1" aria-labelledby="G-vouchers" aria-hidden="true">
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header ps-5 pb-3">
							<h5 className="modal-title" id="G-vouchers">Subscription {appid}</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => this.closeModel(e)}></button>
						</div>
						<div className="modal-body">
							<div className="row mx-auto">
							<div className="col-lg-12 mb-4">
								<div className="login-wrap">
									<h2 className="text-center">Unlock Mindfulness</h2>
									<p>By continuing you will be entitled to get following benefits, which you can avail using Web, Android &amp; iOS apps. Check our <a href={this.props.helpUrls.terms_url ? this.props.helpUrls.terms_url : ""} target='_blank' rel="noopener noreferrer" className="tnc_pop">Terms &amp; Conditions</a> and  <a href={this.props.helpUrls.privacy_url ? this.props.helpUrls.privacy_url : ""} target='_blank' rel="noopener noreferrer" className="redtxt privc_pop">Privacy Policy</a></p>
								</div>
								<div className="row mx-auto">
									<div className="col-lg-9 col-sm-9 align-self-center">
										<ul className="highlights_list" style={{'paddingLeft':'1rem'}}>
											{
												this.state.subscriptionBenefits.map(benefit => {
													return (<li>{benefit}</li>)
												})
											}
										</ul>
									</div>
									<div className="col-lg-3 col-sm-3 bhodi-img-section" style={{'display':'flex','alignItems': 'center'}}>
										<div className="med-img-wraper bhodi-img-div">
											<img src="../../assets/images/med_img.png" alt='bodhi-img' className="img-fluid bhodi-img"/>
										</div>
									</div>
								</div>
								<div className="d-flex justify-content-between mt-3">
									<div className="apply-text align-items-center">
										<label>Apply Code</label>
									</div>
									<div className="d-flex justify-content-end">
										<Link to="" onClick={ (e) => this.ViewOfferToggle(e)}  className="viewall_coupon view_pop input-group-text">View All</Link>
									</div>
								</div>
								<hr className="mt-2 mb-2"/>
								{
									appliedOfferCode ?
									<div className="d-flex justify-content-between mb-2 coupon_add_remove">
										<label className="apply-text">OFFER</label>
										<label className="applied-offer-code">{appliedOfferCode}</label>
										{
										this.state.isNearRenewalUser === 0 ? 
										<Link to="" className="viewall_coupon rem_coupon" onClick={ (e) => this.removePromoCode(e)} >X</Link>
										: ""
										}
										
									</div> :""
								}
								<div className="d-flex justify-content-between mb-2">
									<label className="price_label align-self-center">Price</label>
									<label className="apply-text">Only ₹ {this.state.planPrice} ({this.state.planDuration+' '+this.state.planDurationType})</label>
								</div>
								{
									appliedOfferCode && this.state.offerType==="price" ?
									<div className="d-flex justify-content-end mb-2">
										<label className="disc_price price_label"><span>₹ {this.state.ActualPlanPrice}</span></label>
									</div> : ""
								}
								<form onSubmit={this.handleSubmit} id="subscription" action={this.state.jetPayPayload.transaction_url} method="post" >
									<input type="text" className="d-none" name="appId" value={this.state.jetPayPayload.appId} readOnly/>
									<input type="text" className="d-none" name="operator" value={this.state.jetPayPayload.operator} readOnly/>
									<input type="text" className="d-none" name="partnerId" value={this.state.jetPayPayload.partnerId} readOnly/>
									<input type="text" className="d-none" name="countryName" value={this.state.jetPayPayload.countryName} readOnly/>
									<input type="text" className="d-none" name="cpEvent" value={this.state.jetPayPayload.cpEvent} readOnly/>
									<input type="text" className="d-none" name="transId" value={this.state.jetPayPayload.transId} readOnly/>
									<input type="text" className="d-none" name="cmode" value={this.state.jetPayPayload.cmode} readOnly/>
									<input type="text" className="d-none" name="subAccountId" value={this.state.jetPayPayload.subAccountId} readOnly/>
									<input type="text" className="d-none" name="unitType" value={this.state.jetPayPayload.unitType} readOnly/>
									<input type="text" className="d-none" name="returnUrl" value={this.state.jetPayPayload.returnUrl} readOnly/>
									<input type="text" className="d-none" name="failReturnUrl" value={this.state.jetPayPayload.failReturnUrl} readOnly/>
									<input type="text" className="d-none" name="currency" value={this.state.jetPayPayload.currency} readOnly/>
									<input type="text" className="d-none" name="amount" value={this.state.jetPayPayload.amount} readOnly/>
									<input type="text" className="d-none" name="language" value='en' readOnly/>
									
									<div className="mt-4 ">
										<div className="subscribe-now-btn-section">
											<div className="d-flex justify-content-between">
												<span>₹ {this.state.planPrice}</span>
												<input type="submit" value="Subscribe now" className="continue_btn w-100 d-block cont_pay subscribe-now-btn-div"/>
											</div>
										</div>
									</div>
									
									{/* <div className="mt-4">
										<a href="#" onClick={ (e) => this.handleSubscriptionClick(e)} className="continue_btn w-100 d-block cont_pay">
											<div className="d-flex justify-content-between">
												<span>₹ {this.state.planPrice}</span>
												<span>Subscribe now </span>
											</div>
										</a>
									</div> */}
								</form>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal fade coupan-list-modal" id="coupon_list" tabIndex="-1" aria-labelledby="G-vouchers" aria-hidden="true">
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						{/*  <div className="modal-header border-0">
						<h5 className="modal-title text-center w-100" id="coupons">Apply Code</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div> */}
						<button type="button" className="btn-close coupon_close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => this.closeOfferModel(e)} ></button>
						<div className="modal-body">
						<div className="container">
							<div className="welcome-txt text-center">
								<h2 className="modal-title">Apply Code </h2>
							</div>
							<ul className="list-unstyled coupon_li">
								<li>
									<div className="coupon_inner_wrap coupan-code-input-section">
										<p>Have your own?</p>
										<div className="d-flex justify-content-between">
										<div className="c_code"><input value={this.state.customCouponCode} type="text" className="form-control" name="coupon-code" placeholder='Enter coupon code' onClick={(e) => this.updateInputValue(e)} onChange={(e) => this.updateInputValue(e)} /></div>
											<Link to="" data-val="CUSTOM" className="apply_coupon_btn" onClick={ (e) => this.applyVoucherCode(e)}>Apply Now</Link>
										</div>
										<span>{this.state.errorMessage}</span>
									</div>
								</li>
							</ul>
							<div className="subhead">
								<h4>{offers && offers.length > 0 ? "Available Coupons" : "No Coupons Available"}</h4>
							</div>
							<ul className="list-unstyled coupon_li">
								{
									offers && offers.length > 0 ?

									(offers.map((offerDetail,i) => {
										return (
											<li key={i} >
												<div className="coupon_inner_wrap">
													<div className="d-flex justify-content-between">
														<div className="c_code">{offerDetail.offer_code}</div>
														<Link to="" data-val={offerDetail.offer_code} className="apply_coupon_btn" onClick={ (e) => this.applyVoucherCode(e)}>Apply Now</Link>
													</div>
													<p>{offerDetail.description}</p>
												</div>
											</li>
										)
									})) : ""
								}
							</ul>
						</div>
						</div>
					</div>
				</div>
			</div>
			</>
		)
	}
}