import * as constants from "../Constants";

export async function getSearchKeywords(data){
    const response = await fetch(constants.API_URL+`v3/searchContent/getSearchKeywords_1_0` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}


export async function globalSearch(data){
    const response = await fetch(constants.API_URL+`v3/searchContent/globalSearch_1_0` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}