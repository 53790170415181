import React,{useState} from 'react'
import TestimonialVideoModal from './TestimonialVideoModal';
import * as constants from "../../../Constants";
let thinkrightmeBabyImg = constants.BASE_URL+"/assets/images/thinkrightme-baby-img.svg";

//Component Description - Trusted users section with how to play video component for userbased landing pages
export default function TrustedUsersSection() {
    const [src,setSrc]=useState('');
    const [iframeModal,setIframeModal]=useState(0);

    let path_url='https://d2c018txhbneoo.cloudfront.net/staging/thinkrightAdmin/childpageimage/1681128697.mp4';

    const playTestimonial = (e,src) =>{
        e.preventDefault();
        setSrc(src);
        setIframeModal(true);
    }
  return (
    <>
    <div className="container trusted-users-section ">
        <div className="row col-lg-12 col-md-12 nopadding vertical-center">
            <div className="col-lg-6 col-md-6">
                <h2 className="section-title">A Community Of 5 Million+ Users</h2>
                <p className="section-title-desc">Your connection with others is just as important as your connection with yourself. Be a part of  our growing community.</p>
                <h4 className="subtitle-heading">Know more about ThinkRight.me</h4>
                <button type="button" className="btn how-to-use-btn"  data-bs-toggle="modal" data-bs-target="#videoPopup2" onClick={(e)=>playTestimonial(e,path_url)}><span className="play-orange-white-icon"></span> How to Use ThinkRight.me App</button>
            </div>
            <div className="col-lg-6 col-md-6 trusted-users-image-section">
                <img src={thinkrightmeBabyImg} className="img-responsive trusted-users-baby-img" />
            </div>
        </div>
    </div>
    <TestimonialVideoModal src={src} setSrc={setSrc} iframeModal={iframeModal} setIframeModal={setIframeModal}/>
</>
  )
}
