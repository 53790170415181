//import constants
import * as constants from "../Constants";

export async function getSubscriptionStatus(data){

	const response = await fetch(constants.API_URL+`v3/inApp/getStatus_1_1` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}

export async function getSubscriptionPlans(data){

	const response = await fetch(constants.API_URL+`v3/inApp/plans_1_4` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}

export async function initiateSubscription(data){

	const response = await fetch(constants.API_URL+`v3/inApp/subscribe_1_6` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}

export async function getUserSubscriptionStatus(data){

	const response = await fetch(constants.API_URL+`v3/inApp/getStatus_1_1` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}

export async function applyPromoCodeSubscription(data){

	const response = await fetch(constants.API_URL+`v3/inApp/nonRenewalplans_1_1` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}

export async function userSubscribeByCoupan(data){

	const response = await fetch(constants.API_URL+`v3/inApp/userSubscribeByCoupan_1_2` ,{
		method: 'POST',
		headers:{'Content-Type':'application/json'},
		body: JSON.stringify(data)
	})
	return await response.json();
}

