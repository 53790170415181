import React from 'react';
import {useNavigate, Link} from 'react-router-dom';

export default function purchaseSuccess() {
	let navigate = useNavigate();
	const DashboardToggle = (e) => {
		// window.$("#fullsecreenModal-1").modal("toggle");
		// window.$("#consent").modal("toggle");
		// window.$("#fullsecreenModal-2").modal("toggle");
		// window.$("#fullsecreenModal-3").modal("toggle");
		navigate(`/dashboard/manage_subscription`);
	}

	return (
		<>
		<div className="modal fade payment-success-modal" id="voucher-payment-success" tabIndex="-1" aria-labelledby="modal-3" aria-hidden="true">
			<div className="modal-dialog modal-lg">
			<div className="modal-content">
				{/* <div className="modal-header">
				<h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
				<button type="button" className="btn-close close_btn_right" data-bs-dismiss="modal" aria-label="Close"></button>
				</div> */}
				<button type="button" className="btn-close close_btn_right" data-bs-dismiss="modal" aria-label="Close"></button>
				<div className="container d-flex align-items-center justify-content-center">
				<div className="modal-body">
					<div className="row mx-auto mt-4">
						<div className="col-lg-12 mb-4">
							<div className="logo text-center">
							<img width="285" src="../../assets/images/image-75.png" alt="logo" className="img-fluid d-block mx-auto mb-3"/>
							</div>
							<div className="login-wrap cong_pop">
							<h2 className="text-center mb-4">Congratulations! </h2>
							</div>
							<div className="d-flex justify-content-center">
							<img src="../../assets/images/ico-gift-red.svg" alt='bodhi-img' className="img-fluid bhodi-img"/>
							</div>
							<div className="text-center mt-3 login-wrap">
							<p>You have purchased gift voucher Successfully.</p>
							</div>
							<div className="mt-4">
							<a href="/dashboard/manage_subscription" onClick={ (e) => DashboardToggle(e) } className="continue_btn continue-to-dashboard-btn w-100 d-block text-center">
								Continue
							</a>
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
		</>		
	)
}
