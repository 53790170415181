import React, {useEffect} from 'react';
import { NavLink,useLocation,useNavigate } from "react-router-dom";

//Component Description - Sidebar component for dashboard template
export default function Sidebar() {
	let navigate = useNavigate();
	let location = useLocation();
	useEffect(()=>{
		if(location.pathname==="/dashboard" || location.pathname==="/dashboard/"){
			navigate(`/dashboard/home`);
		}
	},[location]);
  return (
    <aside className="left-side_menu sticky-top">
	<div className="left-sidebar">
		<ul className="list-unstyled">
			<li>
				<NavLink to="/dashboard/home"  className={`${location.pathname==="/dashboard/home" ? "active" : ""}`}>
					<img width="30" src="../dashboard-assets/images/home.svg" alt='sidebar-img' className="me-3 grey-img sidebar-img-inactive"/>
					<img width="30" src="../dashboard-assets/images/home-active.png" alt='sidebar-img' className="me-3 active-red sidebar-img-active"/>
					<span>Home</span>
				</NavLink>
			</li>
			<li>
				<NavLink to="/dashboard/dailyAffirmation" className={`${location.pathname==="/dashboard/dailyAffirmation" ? "active" : ""}`}>
					<img width="30" src="../dashboard-assets/images/Daily-Affirmation .svg" alt='sidebar-img' className="me-3 grey-img sidebar-img-inactive"/>
					<img width="30" src="../dashboard-assets/images/Affirmation-active.png" alt='sidebar-img' className="me-3 active-red sidebar-img-active"/>
				<span>Daily Affirmation </span>
				</NavLink>
			</li>	
			<li><NavLink to="/dashboard/morningzen" className={`${location.pathname==="/dashboard/morning_zen" ? "active" : ""}`}>
                <img width="30" src="../dashboard-assets/images/Morning-Zen.svg" alt='sidebar-img' className="me-3 grey-img sidebar-img-inactive"/>
			    <img width="30" src="../dashboard-assets/images/morning-active.png" alt='sidebar-img' className="me-3 active-red sidebar-img-active"/><span>Morning Zen</span>
				</NavLink>
            </li>

			<li><NavLink to="/dashboard/meditation" className={`${location.pathname==="/dashboard/meditatation" ? "active" : ""}`}>
				<img width="30" src="../dashboard-assets/images/Meditation .svg" alt='sidebar-img' className="me-3 grey-img sidebar-img-inactive"/>
				<img width="30" src="../dashboard-assets/images/meditation-active.png" alt='sidebar-img' className="me-3 active-red sidebar-img-active"/><span>Meditation</span> 
			</NavLink></li>

			<li><NavLink to="/dashboard/sound" className={`${location.pathname==="/dashboard/sound" ? "active" : ""}`}>
				<img width="30" src="../dashboard-assets/images/sound.png" alt='sidebar-img' className="me-3 grey-img sidebar-img-inactive"/>
				<img width="30" src="../dashboard-assets/images/sound-active.png" alt='sidebar-img' className="me-3 active-red sidebar-img-active"/><span>Sound</span> </NavLink>
			</li>

			<li><NavLink to="/dashboard/yoga" className={`${location.pathname==="/dashboard/yoga" ? "active" : ""}`}>
				<img width="25" src="../dashboard-assets/images/yoga.png" alt='sidebar-img' className="me-3 grey-img sidebar-img-yoga-inactive"/>
				<img width="25" src="../dashboard-assets/images/yoga-active.png" alt='sidebar-img' className="me-3 active-red sidebar-img-yoga-active"/><span>Yoga</span> </NavLink>
			</li>

			{/* <li><NavLink to="/dashboard/helpandtips" className={`${location.pathname==="/dashboard/helpandtips" ? "active" : ""}`}>
				<img width="30" src="../dashboard-assets/images/help.svg" alt='sidebar-img' className="me-3 grey-img sidebar-img-inactive"/>
				<img width="30" src="../dashboard-assets/images/help-active.png" alt='sidebar-img' className="me-3 active-red sidebar-img-active"/><span>Help and Tips</span> </NavLink>
			</li> */}
		</ul>
	</div>
</aside>
  )
}
