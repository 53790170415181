import React from "react";
import {Link} from "react-router-dom";
import * as constants from "../../../../Constants";
import {userPassbookData,getVoucherPlans,initiateVoucherPurchaseTxn, getTransactionVoucherData, shareGiftVoucher} from "../../../../services/Voucher";
import {generateInvoice} from "../../../../services/Invoice";
import {getUserProfileDetails,updateUserProfileDetails} from "../../../../services/Profile";
import ShareSocial from "../content-card/ShareSocial";

import OwlCarousel from "react-owl-carousel";

export default class GiftVoucherDetails extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isVoucherPurchase : false,
            voucherBalance : 0,
            passbookHistory:[],
            voucherPlans: [],
            subscriptionPackageId:"",
            paymentPayload : [],
            voucherPlanId: 0,
            invoiceMessage: "",
            voucherTransactionData: [],
            displaySharePop:0,
            openSocialShare: false,
            shareDetails:{},
            email: "",
            invoiceDetails : {},
            showUpdateEmailInput:false,
            errorMessage: ""
        }

        this.jetPayPayload = [];

        this.getUserPassbookData();
        
    }

    componentDidUpdate() {
        if(this.props.openVoucherPop === true){
            this.voucherToggle();
            this.props.handleOpenVoucherPop(false);
        }
    }

    getUserPassbookData(){
        let userPassbookDataPayload = {
            userCode : localStorage.getItem('userCode'),
            superStoreId : constants.SUPER_STORE_ID,
            locale: constants.LOCALE
        };
        userPassbookData(userPassbookDataPayload).then(response =>{
            if( response.code === 200 && response.data !== "" && response.data.passbook_history.length === 0  ){
                this.setState({isVoucherPurchase:false});
            }else if ( response.code === 200 && response.data !== "" && response.data.passbook_history.length > 0 ){
                this.setState({isVoucherPurchase:true});
                this.setState({voucherBalance:response.data.voucher_balance});
                this.setState({passbookHistory:response.data.passbook_history});
            }
        });
    }


    voucherToggle = (e) => {
        if(this.state.isVoucherPurchase === true){
            window.$("#gift-vouchers-purchase-details").modal("show");
        }else{
            window.$("#gift-vouchers-benefits").modal("show");
        }
    }

    selectVoucherPlan = (voucherPlanId) => {        
        this.setState({voucherPlanId:voucherPlanId});
    }

    buyGiftVoucher = (e) => {

        let getVoucherPlanPayload = {
            userCode : localStorage.getItem('userCode'),
            userType : localStorage.getItem('userBase'),
            superStoreId : constants.SUPER_STORE_ID,
            locale: constants.LOCALE
        }

        getVoucherPlans(getVoucherPlanPayload).then(response=>{
            
            if(response.code === 200 && response.data.buy_options !== ''){
                this.setState({voucherPlans:response.data.buy_options});

                //Initiate voucher purchase transaction
                //Loop over the each voucher and prepre initiate voucher purchase transaction
                response.data.buy_options.map(voucherDetails =>{
                    let voucherInfo = {};
                    voucherInfo.packageName = voucherDetails.packageName;
                    voucherInfo.planId      = voucherDetails.planId;
                    voucherInfo.amount      = voucherDetails.channel[0]['pricePointValue'][0]['value'];

                    let initateVoucherPurchasePayload = {};
                    initateVoucherPurchasePayload.superStoreId  = constants.SUPER_STORE_ID;
                    initateVoucherPurchasePayload.userCode      = localStorage.getItem('userCode');
                    initateVoucherPurchasePayload.storeId 	    = voucherDetails.storeId;
                    initateVoucherPurchasePayload.parentPackageId 	= voucherDetails.packageId;
                    initateVoucherPurchasePayload.packageId 	= voucherDetails.packageId;
                    initateVoucherPurchasePayload.planId 		= voucherDetails.planId;
                    initateVoucherPurchasePayload.channelId 	= voucherDetails.channel[0]['channelId'];
                    initateVoucherPurchasePayload.eventId 		= voucherDetails.channel[0]['channelEventId'];
                    initateVoucherPurchasePayload.amount 		= voucherDetails.channel[0]['pricePointValue'][0]['value'];
                    initateVoucherPurchasePayload.cmode 		= "APP";
                    initateVoucherPurchasePayload.unitType 	    = voucherDetails.unitType;
                    initateVoucherPurchasePayload.channelTag 	= voucherDetails.channel[0]['channelTag'];
                    initateVoucherPurchasePayload.currency 	    = voucherDetails.channel[0]['pricePointValue'][0]['currency'];
                    initateVoucherPurchasePayload.locale        = constants.LOCALE;
                    this.initiateVoucherPurchase(initateVoucherPurchasePayload, voucherInfo);

                    if(voucherDetails.packageName.includes("Popular")){
                        this.selectVoucherPlan(voucherDetails.planId);
                    }
                });
            }
        });
        window.$("#gift-vouchers-purchase-details").modal("hide");
        window.$("#gift-vouchers-benefits").modal("hide");
        window.$("#buy-gift-voucher").modal("show");
    }

    //START init subscription
	initiateVoucherPurchase(initateVoucherPurchasePayload,voucherInfo){
		//prepare initiate subscription payload to get txid other details for jetpay payload

		initiateVoucherPurchaseTxn(initateVoucherPurchasePayload).then(response => {
			if( response.code === 200 && response.data !== undefined ){
				let payLoad = response.data.payload;
                payLoad.packageName         = voucherInfo.packageName;
                payLoad.planId              = voucherInfo.planId;
                payLoad.transaction_url     = response.data.transaction_url;
                payLoad.transactionAmount   = voucherInfo.amount;

                this.jetPayPayload.push(payLoad);
                this.setState({paymentPayload:this.jetPayPayload});
			}
		});
	}
	//END init subscription

    purchaseVoucher = (e) => {
        document.getElementById("voucher-"+this.state.voucherPlanId).submit();
    }


    downloadInvoice = (e,postedInvoiceDetails,callBack) =>{

        if(callBack == "downloadInvoice"){
            postedInvoiceDetails.superStoreId     = constants.SUPER_STORE_ID;
            postedInvoiceDetails.unitType         = "EVENT";
            postedInvoiceDetails.userCode         = localStorage.getItem('userCode');
            postedInvoiceDetails.locale           = constants.LOCALE;
            this.setState({invoiceDetails:postedInvoiceDetails});
        }else{
            postedInvoiceDetails = this.state.invoiceDetails;
        }

        generateInvoice(postedInvoiceDetails).then(response => {
            if( response.code === 608 ){ //608 code means email missing
                this.setState({showUpdateEmailInput:true});
            }
            this.setState({invoiceMessage:response.message});
            window.$("#download-invoice-msg").modal("show");
        });
    }

    giftNow = (e,invoiceDetails) =>{
        invoiceDetails.superStoreId     = constants.SUPER_STORE_ID;
        invoiceDetails.userCode         = localStorage.getItem('userCode');
        invoiceDetails.locale           = constants.LOCALE;
        
        getTransactionVoucherData(invoiceDetails).then(response => {
            if(response.code === 200){
                this.setState({voucherTransactionData:response.data});
                window.$("#share-voucher").modal("show");
            }
        });
    }

    handleSocialShare = openSocialShare =>{
        this.setState({openSocialShare})
    }

    shareVoucher = (e,shareUrl) => {
        this.setState({displaySharePop:1});

        let shareVoucherDetails = {};
        shareVoucherDetails.desc = "You've received a gift! Start your journey of transformation with this subscription to ThinkRight.me App. Click on the link to activate your subscription";
        shareVoucherDetails.link = shareUrl;

        let voucherCode = shareUrl.split("=")[2];

        this.setState({shareDetails:shareVoucherDetails});
        this.handleSocialShare(true)

        //Call share gift voucher API
        let shareGiftVoucherPayload = {};
        shareGiftVoucherPayload.superStoreId    = constants.SUPER_STORE_ID;
        shareGiftVoucherPayload.userCode        = localStorage.getItem('userCode');
        shareGiftVoucherPayload.voucherCode     = voucherCode;
        shareGiftVoucherPayload.locale          = constants.LOCALE;

        shareGiftVoucher(shareGiftVoucherPayload);
    }

    renderShareGiftVoucher(){
        return (<ShareSocial openSocialShare={this.state.openSocialShare} handleSocialShare={this.handleSocialShare} shareDetails={this.state.shareDetails} />);
    }

    closeModel(event){
        if(this.props.openVoucherPop === true){
            this.props.handleOpenVoucherPop(false);
        }

        this.props.setvoucherPurchaseSource("");
        
        let id = event.target.id.replace("btn-","");

        if(id !== "gift-vouchers-purchase-details" &&  id !== "gift-vouchers-benefits"){
            if(this.state.isVoucherPurchase === true){
                window.$("#gift-vouchers-purchase-details").modal("show");
            }else{
                window.$("#gift-vouchers-benefits").modal("show");
            }
            window.$("#"+id).modal("hide");
        }else{
            window.$("#"+id).modal("hide");
        }

        this.getUserPassbookData();
    }

    onChangeForm = (e) => {
        let tempUser = {};
        if (e.target.name === 'email') {
            this.setState({email:e.target.value});
        }
    }

    updateEmail = (e) =>{

        let updateEmailPayload = {};
        updateEmailPayload.superStoreId = constants.SUPER_STORE_ID;
        updateEmailPayload.userCode     = localStorage.getItem('userCode');
        updateEmailPayload.email        = this.state.email;
        updateEmailPayload.locale       = constants.LOCALE;

        let getUserDetailsPayload = {};
        getUserDetailsPayload.superStoreId = constants.SUPER_STORE_ID;
        getUserDetailsPayload.userCode     = localStorage.getItem('userCode');
        getUserDetailsPayload.locale       = constants.LOCALE;

        getUserProfileDetails(getUserDetailsPayload).then(response =>{

            updateEmailPayload.full_name = response.data.name;
            updateEmailPayload.stateId   = response.data.store_id;
            updateEmailPayload.mobile    = response.data.mobile;
            updateEmailPayload.status    = "nochange";

            updateUserProfileDetails(updateEmailPayload).then(response =>{

                if(response.code === 200){
                    this.setState({showUpdateEmailInput:false});
                    this.downloadInvoice(this.state.invoiceDetails,"fromUpdateEmail");
                    this.setState({errorMessage:""});
                }else{
                    this.setState({errorMessage:response.message});
                }
            });
        });
    }


    render(){
        let voucherNumber = 0;

        return(
            <>
            <div className="col-6">
                <div className="spread-joy">
                    <div className="d-flex">
                        <div className="card-right-text align-self-center">
                            <h4>Spread Joy and Happiness </h4>
                            <p>Show you care by gifting an annual subscription to your loved ones.</p>
                            <div className="">
                                <Link to="" onClick={(e) => this.voucherToggle(e)} className="d-block">Gift Now </Link>
                            </div>
                        </div>
                        <div className="card-right-img">
                            <div className="position-relative">
                                <img src="../dashboard-assets/images/gift-card.png" className="img-fluid"/>
                                <img src="../dashboard-assets/images/med-img.png" className="med-img-abs img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Gift Vouchers*/}
            <div className="modal fade" id="gift-vouchers-benefits" tabIndex="-1" aria-labelledby="G-vouchers" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                <div className="modal-header ps-5 pb-3">
                    <h5 className="modal-title" id="G-vouchers">Gift Vouchers</h5>
                    <button id="btn-gift-vouchers-benefits" type="button" className="btn-close" aria-label="Close" onClick={(e) => this.closeModel(e)} ></button>
                </div>
                <div className="modal-body p-5 pb-3">
                    <div className="top-h-txt">
                    <h4>Gift emotional wellbeing to your loved onse </h4>
                    <p>Make this festive season extra special for your loved ones. Brighten up their life by gifting them a subscription now!</p>
                    </div>
                    <div className="voucher-blnc mb-3 mt-3">
                    <div className="spread-joy border-silver">
                        <div className="d-flex justify-content-between">
                            <div className="card-right-text align-self-center">
                            <h4>Vouchers Balance </h4>
                            <p>No Voucher available</p>
                            </div>
                            <div className="card-right-img">
                            <div className="position-relative">
                                <img width="140" src="../dashboard-assets/images/gift-card.png" className="img-fluid"/>
                                <img src="../dashboard-assets/images/med-img.png" className="med-img-abs img-fluid"/>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="benefits-list">
                    <h4>Gift Voucher Benefits </h4>
                    <ul className="list-unstyled">
                        <li>Library of guided meditations by India's top meditation gurus.
                        </li>
                        <li> Music tracks to assist your meditation practices.</li>
                        <li>
                            A collection of yoga techniques and practices by India's leading yoga gurus.
                        </li>
                        <li>A journal to pen down your thoughts and reflect on your day.
                        </li>
                        <li>Meditation tools to enhance your mindfulness experience.</li>
                    </ul>
                    <div className="btnwith-txt">
                        <a href='' data-bs-toggle="modal" onClick={(e)=>this.buyGiftVoucher(e)} data-bs-target="#Gift-vouchers2" className="btn-style buy-now-btn d-block mb-1">Buy Now</a>
                        <p>Fore more details, please write to us at <a href="mailto:support@thinkright.me" className="redtxt">support@thinkright.me</a></p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            {/* Modal Gift Vouchers 2*/}
            <div className="modal fade" id="buy-gift-voucher" tabIndex="-1" aria-labelledby="G-vouchers2" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header ps-5 pb-3">
                    <h5 className="modal-title" id="G-vouchers2">Gift Vouchers</h5>
                    <button id="btn-buy-gift-voucher" type="button" className="btn-close" aria-label="Close" onClick={(e) => this.closeModel(e)} ></button>
                    </div>
                    <div className="modal-body p-5 pb-3">
                    <div className="benefits-list">
                        {
                            this.state.paymentPayload.map(planDetails => {
                                return(
                                    <>
                                    <form key={planDetails.planId} onSubmit={this.handleSubmit} id={"voucher-"+planDetails.planId} action={planDetails.transaction_url} method="post" >
                                        <input type="text" className="d-none" name="appId" value={planDetails.appId} readOnly/>
                                        <input type="text" className="d-none" name="operator" value={planDetails.operator} readOnly/>
                                        <input type="text" className="d-none" name="partnerId" value={planDetails.partnerId} readOnly/>
                                        <input type="text" className="d-none" name="countryName" value={planDetails.countryName} readOnly/>
                                        <input type="text" className="d-none" name="cpEvent" value={planDetails.cpEvent} readOnly/>
                                        <input type="text" className="d-none" name="transId" value={planDetails.transId} readOnly/>
                                        <input type="text" className="d-none" name="cmode" value={planDetails.cmode} readOnly/>
                                        <input type="text" className="d-none" name="subAccountId" value={planDetails.subAccountId} readOnly/>
                                        <input type="text" className="d-none" name="unitType" value={planDetails.unitType} readOnly/>
                                        <input type="text" className="d-none" name="returnUrl" value={planDetails.returnUrl} readOnly/>
                                        <input type="text" className="d-none" name="failReturnUrl" value={planDetails.failReturnUrl} readOnly/>
                                        <input type="text" className="d-none" name="currency" value={planDetails.currency} readOnly/>
                                        <input type="text" className="d-none" name="amount" value={planDetails.amount} readOnly/>
                                        <input type="text" className="d-none" name="language" value='en' readOnly/>
                                    </form>
                                    </>
                                )
                            })
                        }

                        {
                            this.state.voucherPlans.map(planDetails => {
                                return(
                                    <div key={planDetails.planId} onClick={(e) => this.selectVoucherPlan(planDetails.planId)} tabIndex="-1" className={this.state.voucherPlanId !== 0 && planDetails.planId === this.state.voucherPlanId ? "d-flex justify-content-between voucher-item voucher-selection" : "d-flex justify-content-between voucher-item" }>
                                        <span>{planDetails.packageName}</span>
                                        <span>₹{planDetails.channel[0]['pricePointValue'][0]['value']}</span>
                                    </div>
                                )
                            })
                        }
                        <div className="btnwith-txt mt-5">
                            <a href='' className="btn-style buy-now-btn d-block mb-1" onClick={(e)=>this.purchaseVoucher(e)} data-bs-toggle="modal" data-bs-target="#Gift-vouchers3">Buy Now</a>
                            <p>To learn More <a href={this.props.helpUrls.voucher_no_more} target='_blank' className="redtxt">Click Here</a></p>
                            
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            {/* Modal Gift Vouchers 3*/}
            <div className="modal fade" id="gift-vouchers-purchase-details" tabIndex="-1" aria-labelledby="G-vouchers3" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                    <div className="modal-header ps-5 pb-3">
                        <h5 className="modal-title" id="G-vouchers3">Gift Vouchers</h5>
                        <button id="btn-gift-vouchers-purchase-details" type="button" className="btn-close" aria-label="Close" onClick={(e) => this.closeModel(e)}></button>
                    </div>
                        <div className="modal-body p-5 pb-3">
                        <div className="top-h-txt">
                            <h4>Gift emotional wellbeing to your loved onse </h4>
                            <p>Make this festive season extra special for your loved ones. Brighten up their life by gifting them a subscription now!</p>
                        </div>
                        <div className="voucher-blnc mb-3 mt-3">
                            <div className="spread-joy border-silver mb-5">
                                <div className="d-flex justify-content-between">
                                <div className="card-right-text align-self-center">
                                    <h4>Vouchers Balance</h4>
                                    <p>You have {this.state.voucherBalance} vouchers</p>
                                </div>
                                <div className="card-right-img">
                                    <div className="position-relative">
                                    <img width="140" src="../dashboard-assets/images/gift-card.png" className="img-fluid"/>
                                    <img src="../dashboard-assets/images/med-img.png" className="med-img-abs img-fluid"/>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="btnwith-txt mb-5">
                                <Link to="" onClick={(e) => this.buyGiftVoucher(e)} className="btn-style buy-now-btn d-block mb-1">Buy Now </Link>
                            </div>
                            <div className="coupon-details-wrap">
                                <OwlCarousel className="coupon-slider owl-carousel" stagePadding={150} margin={15} items={1}>
                                {
                                    this.state.passbookHistory.map(purchaseDetail => {
                                        let invoiceDetails = {};

                                        invoiceDetails.transactionId    = purchaseDetail.transaction_id;

                                        return(
                                            <div key={purchaseDetail.transaction_id} className="slider-element">
                                                <h4>Vouchers Purchased</h4>
                                                <div className="d-flex justify-content-start mb-2">
                                                {
                                                    purchaseDetail.status.toLowerCase() === "success" ?
                                                        <>
                                                        <div className="redeemed me-3 greentxt">
                                                            Redeemed {purchaseDetail.voucher_consumed_count}
                                                        </div>
                                                        <div className="redeemed bal-0">
                                                            Balance {purchaseDetail.voucher_purchase_count - purchaseDetail.voucher_consumed_count}
                                                        </div></>
                                                    :
                                                    <div className="redtxt">
                                                        {purchaseDetail.status}
                                                    </div>
                                                }
                                                </div>
                                                <div className="datetime nrmaltxt mb-2">
                                                {purchaseDetail.event_date}
                                                </div>
                                                <div className="transition-id mb-4 nrmaltxt mb-2">
                                                Transaction id - {purchaseDetail.transaction_id}
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                <Link className="download-invoice" to="" onClick={(e) => this.downloadInvoice(e,invoiceDetails,"downloadInvoice")}>Invoice 
                                                    <span className="ms-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                                        <path d="M0 17H18V19H0V17ZM10 11.172L16.071 5.1L17.485 6.514L9 15L0.515 6.515L1.929 5.1L8 11.17V0H10V11.172Z" fill="black"/>
                                                        </svg>
                                                    </span>
                                                </Link>
                                                <Link className="gift-now" to="" onClick={(e) => this.giftNow(e,invoiceDetails)}>Gift NOW</Link>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </OwlCarousel>
                                <div className="btnwith-txt mb-5 mt-4">
                                    <p>Fore more details, please write to us at <a href="mailto:support@thinkright.me" className="redtxt">support@thinkright.me</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            {/* Modal Gift Vouchers 2*/}
            <div className="modal fade" id="share-voucher" tabIndex="-1" aria-labelledby="share-v" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header ps-5 pb-3">
                        <h5 className="modal-title" id="share-v">Share gift voucher</h5>
                        <button id="btn-share-voucher" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => this.closeModel(e)} ></button>
                        </div>
                        <div className="modal-body p-5 pb-3">
                        <div className="sharev">
                            <ul className="list-unstyled">
                                {
                                    this.state.voucherTransactionData.map(voucherDetails => {
                                        voucherNumber = voucherNumber + 1;
                                        return(
                                            <li className="mb-3">
                                                <div className="d-flex justify-content-between">
                                                    <div className="share-left-wrap d-flex flex-column">
                                                        <h4 className="mb-3">Voucher {voucherNumber}</h4>
                                                        {
                                                            voucherDetails.isConsumable.toLowerCase() === "no" ? <>
                                                                <p>Redeemed by {voucherDetails.mobile}</p>
                                                                </>
                                                            :
                                                            voucherDetails.isAlreadyShared.toLowerCase() === "yes" ? <>
                                                                <p>This voucher is already shared.</p>
                                                                </>
                                                            :
                                                            <p className="minh24"></p>
                                                        }

                                                        <div className="sharebtn-wrap">
                                                            {
                                                                voucherDetails.isConsumable.toLowerCase() === "no" ?
                                                                <Link to="" className="gift-now share-btn disablebtn" data-bs-toggle="modal" data-bs-target="#share-social">share<span className="ms-2"><img src="../dashboard-assets/images/share.svg" className="img-fluid"/></span></Link> :
                                                                <Link to="" className="gift-now share-btn" data-bs-toggle="modal" data-bs-target="#share-social" onClick={(e) => this.shareVoucher(e,voucherDetails.shareUrl)} >share<span className="ms-2"><img src="../dashboard-assets/images/share.svg" className="img-fluid"/></span></Link>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="gift-img align-self-center">
                                                        <img src="../dashboard-assets/images/gift-v.png" className="img-fluid"/>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                        <div className="btnwith-txt mt-5">
                            <p>Fore more details, please write to us at <a href="mailto:support@thinkright.me" className="redtxt">support@thinkright.me</a></p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
                            
            {
                this.state.displaySharePop === 1 ? this.renderShareGiftVoucher() : ""
            }

            {/* Modal Gift Vouchers 2*/}
            
            {/* <div className="modal fade" id="share-social" tabIndex="-1" aria-labelledby="share-s" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header ps-5 pb-3">
                        <h5 className="modal-title" id="share-s">Share</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body ">
                            <ul className="list-unstyled list-inline mb-5">
                            <li className="list-inline-item"><a href=""><img width="70" src="../dashboard-assets/images/WhatsApp.svg"/></a></li>
                            <li className="list-inline-item"><a href=""><img width="70" src="../dashboard-assets/images/Telegram.svg"/></a></li>
                            <li className="list-inline-item"><a href=""><img width="70" src="../dashboard-assets/images/email.svg"/></a></li>
                            </ul>
                            <div className="copyurl position-relative mb-5">
                            <input type="text" name="" className="form-control copyval" id="copyval" value="watch?v=qh7lxQPYb2o" readOnly/>
                            <a  className="copybtn">Copy</a>
                            <div id="copied-success" className="copied">
                                <span>Copied!</span>
                            </div>
                            </div>
                            
                        </div>
                    </div>
                    </div>
                </div> */}

            {/* Modal Gift Vouchers 2*/}
            <div className="modal fade" id="download-invoice-msg" tabIndex="-1" aria-labelledby="share-s" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header ps-5 pb-3">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body ">
                        
                            <div className="copyurl position-relative mb-5">
                                {this.state.invoiceMessage}

                                {
                                    this.state.showUpdateEmailInput === true ?
                                    <form action="#" id="loginform" className="loginform" name="loginform">
                                        <div className="mb-3 input-group">
                                            <div className="input-group">
                                                <input id="email" type="email" onChange={(e) => this.onChangeForm(e)} name="email" value={this.state.email} className="form-control copyval" placeholder="Email" required/>
                                            </div>
                                            <span className="error">
                                                {this.state.errorMessage && this.state.errorMessage !=="" ?  this.state.errorMessage : ""}
                                            </span>
                                        </div>
                                        
                                        <div className="text-center">
                                            <a type="button" name="submit" className="gift-now" 
                                            onClick={() =>  {this.updateEmail(); }} >Save</a>
                                        </div>
                                    </form>
                                    : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }


}